import Country from "@Shared/types/enums/Country";
import LangCode from "@Shared/types/enums/LangCode";
import { parsePhoneNumber } from "libphonenumber-js";

const LANG_CODE_TO_COUNTRY: Record<LangCode, Country | undefined> = {
    [LangCode.LT]: Country.LT,
    [LangCode.EN]: Country.CA,
};

// TBD: This should perfectly only use country and the translation from langCode > country should be removed. In order to do this, need to make SMSService work with Country instead of LangCode first.
/* will return void if unidentified sms format */
export default function intlPhoneNumber(
    phoneNr: string,
    countryOrLangCode: { country: Country } | { langCode: LangCode }
): string | void {
    let intlNumber;
    const trimmedPhoneNr = phoneNr.replace(/[^\d+]|(?!^)\+/g, ""); // trim all everything that is non digit, just keep the + at the beggining.
    const country =
        "country" in countryOrLangCode
            ? countryOrLangCode.country
            : LANG_CODE_TO_COUNTRY[countryOrLangCode.langCode];

    if (!country) {
        throw new Error(
            `Country not detected from: ${JSON.stringify(countryOrLangCode)}`
        );
    }

    if (parsePhoneNumber(trimmedPhoneNr, country)?.isValid()) {
        intlNumber = parsePhoneNumber(trimmedPhoneNr, country).number;
    } else if (parsePhoneNumber(trimmedPhoneNr)?.isValid()) {
        intlNumber = parsePhoneNumber(trimmedPhoneNr, country).number;
    } else {
        console.error(`Phone number unknown format: ${phoneNr}`);
        return;
    }

    // if (trimmedPhoneNr.startsWith("86")) {
    //     intlNumber = trimmedPhoneNr.replace("86", "+3706");
    // } else if (trimmedPhoneNr.startsWith("0")) {
    //     intlNumber = trimmedPhoneNr.replace("0", "+370");
    // } else if (trimmedPhoneNr.startsWith("+370")) {
    //     intlNumber = trimmedPhoneNr;
    // } else if (trimmedPhoneNr.startsWith("+1")) {
    //     intlNumber = trimmedPhoneNr;
    // } else if (trimmedPhoneNr.length === 10 && /^\d/.test(trimmedPhoneNr)) {
    //     // is a local CA phone number that starts with digit and not a plus
    //     intlNumber = `+1${trimmedPhoneNr}`;
    // }

    return intlNumber;
}

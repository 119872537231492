import useStore from "@FEClient/logic/store";
import { Checkbox, FormControlLabel } from "@mui/material";
import {
    genOrderSuccessPathname,
    countryClientPageToMeta,
} from "@Shared/util/clientPagesMeta";
import { runInAction } from "mobx";
import React from "react";
import * as S from "./ReservationConfirmation.styled";
import * as CS from "../../commonComps/ConfirmationDetailsBox/ConfirmationDetailsBox.styled";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
    useBookServiceMutation,
    useBookServiceWithoutOrderMutation,
    useReservationConfirmationInitDataQuery,
} from "@FEShared/graphql/generated/graphql";
import { Redirect } from "react-router-dom";
import { history } from "@FEClient/logic/store";
import showToast from "FEShared/utils/showToast";
import isValidPhoneNr from "@Shared/util/isValidPhoneNr";
import isValidEmail from "@Shared/util/isValidEmail";
import useIsMobile from "../../../../../FEShared/hooks/useIsMobile";
import ConfirmationTitle from "@FEClient/views/commonComps/ConfirmationTitle/ConfirmationTitle";
import ConfirmationSidePanel from "@FEClient/views/commonComps/ConfirmationSidePanel/ConfirmationSidePanel";
import Input from "@FEShared/components/UI/Input/Input";
import { STAGES_ORDER } from "@FEShared/components/UI/CarAutocomplete/CarAutocomplete.consts";
import SearchInputs from "../Home/SearchForm/SearchInputs/SearchInputs";
import InputsGroup from "@FEShared/components/UI/InputsGroup/InputsGroup";
import useInitSearchFormInfo from "@FEClient/logic/hooks/useInitSearchFormInto/useInitSearchFormInfo";
import Text from "@FEShared/components/UI/Text/Text";
import prodRealUsersOnly from "@FEClient/logic/utils/prodRealUsersOnly";
import Button from "@FEShared/components/UI/Button/Button";
import Box from "@FEShared/components/UI/Box/Box";
import BackButton from "@FEShared/components/UI/BackButton/BackButton";
import sleep from "@Shared/util/sleep";
import serviceCategoriesToWantedServices from "@Shared/util/serviceCategoriesToWantedServices";
import { TranslatableCustomID_PopularServices } from "@Shared/types/enums/TranslatableCustomID";
import { TransMsg, transStr } from "@FEShared/i18n";
import transCommonTranslatable from "@FEShared/utils/transCommonTranslatable";

const translatables = () => ({
    RESERVATION_CONFIRMATION: transStr("Rezervacijos patvirtinimas", {
        id: "oIcP6zw9",
    }),
});

const countryClientPageMeta = countryClientPageToMeta(window._COUNTRY);

export enum ConfStep {
    First,
    Second,
}

const ReservationConfirmation = observer(() => {
    const GS = useStore();
    const RCS = GS.reservationConfirmationPageState;
    const isMobile = useIsMobile();
    const [bookServiceRes, bookService] = useBookServiceMutation();
    const [_, bookServiceWithoutOrder] = useBookServiceWithoutOrderMutation();
    useInitSearchFormInfo(); // this is needed to make sure we have proper price data for services;

    const didUserSelectCarFullyOnInit = React.useRef(
        GS.searchState.carDataArr.length === STAGES_ORDER.length
    );

    const selectedWorkshop =
        GS.reservationConfirmationPageState.selectedWorkshop;

    const order = GS.searchPageState.primarySearchResults?.order;

    const [initData] = useReservationConfirmationInitDataQuery({
        variables: {
            getEarliestAvailableTimeParams: {
                serviceID: selectedWorkshop?.ID,
            },
            workshopID: selectedWorkshop?.ID || -1,
        },
    });
    const datePickerFnsRef = React.useRef<{ open: () => void }>(null);
    const carsAutocompleteFnsRef = React.useRef<{ open: () => void }>(null);

    // TBD move getters out of objects, this they are inside object because of legacy
    const LS = useLocalObservable(() => ({
        forceInputErrors: false,
        promoCode: undefined as undefined | string,
        promoInputVisible: true,
        showCarPicker: false,
        emailInput: {
            get isErr() {
                return !isValidEmail(
                    GS.reservationConfirmationPageState.emailInputVal
                );
            },
        },
        phoneInput: {
            get isErr() {
                return !isValidPhoneNr(
                    GS.reservationConfirmationPageState.phoneInputVal,
                    window._COUNTRY
                );
            },
        },
        get commentFieldError() {
            return (
                !RCS.comment &&
                GS.searchState.selectedServicesCustomIDs.includes(
                    TranslatableCustomID_PopularServices.UNKNOWN_PROBLEM
                )
            );
        },
        get showMarketingConsent() {
            return GS.reservationConfirmationPageState.emailInputVal.length > 0;
        },
        marketingAgreement: {
            value: false,
        },
        fakeLoading: false,
    }));

    const wantedServices = React.useMemo(() => {
        return selectedWorkshop?.services
            .filter((s) =>
                GS.searchState.selectedServicesDefinitionsIDs.includes(
                    s.type.ID
                )
            )
            .map((s) => serviceCategoriesToWantedServices(s));
    }, [
        GS.searchState.selectedServicesDefinitionsIDs,
        selectedWorkshop?.services,
    ]);

    React.useEffect(() => {
        prodRealUsersOnly(() => {
            window.gtag?.("event", "initiate_checkout");
            window.fbq?.("track", "InitiateCheckout");
            window.hj?.("event", "initiate_checkout");
        });
    }, []);

    React.useEffect(() => {
        runInAction(() => {
            RCS.step = ConfStep.First;
        });
    }, [RCS]);

    React.useEffect(() => {
        return () => {
            runInAction(() => {
                GS.reservationConfirmationPageState.rebookingFromOrderID =
                    undefined;
            });
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const loading = LS.fakeLoading || bookServiceRes.fetching;

    const confirmBtnClick = React.useCallback(async () => {
        runInAction(() => {
            LS.forceInputErrors = true;
        });

        if (
            GS.reservationConfirmationPageState.date.obj.YYYY_MM_DD ===
                undefined ||
            GS.reservationConfirmationPageState.date.obj.HH_MM === undefined
        ) {
            console.error(
                "Date was not selected in 2nd step of reservation confirmation"
            );
            return showToast.warn(
                transStr("Pasirinkite atvykimo laiką!", { id: "nrK6jiNK" })
            );
        }

        if (
            !GS.workshopPageState.fromBookNow &&
            GS.searchState.selectedServicesTransNames.length === 0
        ) {
            return showToast.error(
                transStr("Pasirinkite bent vieną paslaugą!", { id: "yGdPmbFT" })
            );
        }
        if (
            !GS.reservationConfirmationPageState.phoneInputVal ||
            !isValidPhoneNr(
                GS.reservationConfirmationPageState.phoneInputVal,
                window._COUNTRY
            )
        ) {
            return;
        }
        if (
            !GS.reservationConfirmationPageState.emailInputVal ||
            !isValidEmail(GS.reservationConfirmationPageState.emailInputVal)
        ) {
            return;
        }

        let res: Awaited<
            ReturnType<typeof bookService | typeof bookServiceWithoutOrder>
        >;

        runInAction(() => {
            LS.fakeLoading = true;
        });
        await sleep(2000);

        try {
            if (!selectedWorkshop) {
                console.error(`selectedWorkshop not found in reservConf`);
                return showToast.error(
                    transStr("Įvyko netikėta klaida #OOOO", { id: "eq2qByUF" }),
                    true
                );
            }
            if (order) {
                if (!GS.searchPageState.primarySearchResults) {
                    return showToast.error(
                        transStr(
                            "Netikėta klaida. #AAAO. Jei problema kartosis, susisiekite su mumis.",
                            { id: "n8R1FvVb" }
                        )
                    );
                }
                res = await bookService({
                    bookServiceInput: {
                        orderID: order.ID,
                        serviceID: selectedWorkshop.ID,
                        accessToken:
                            GS.searchPageState.primarySearchResults.order
                                .userAccessToken,
                    },
                    updateOrderInput: {
                        ID: order.ID,
                        phoneNumber:
                            GS.reservationConfirmationPageState.phoneInputVal
                                .trim()
                                .replace(/ /g, ""),
                        email: GS.reservationConfirmationPageState
                            .emailInputVal,
                        marketingConsent: LS.marketingAgreement.value,
                        arrivalDate:
                            GS.reservationConfirmationPageState.date.fullDate,
                        servicesDefinitionsIDs:
                            GS.searchState.selectedServicesDefinitionsIDs,
                        customerComment:
                            GS.reservationConfirmationPageState.comment,
                        vehicleBrand: GS.searchState.carData.vehicleBrand,
                        vehicleYear: +GS.searchState.carData.vehicleYear,
                        vehicleModel: GS.searchState.carData.vehicleModel,
                        fromBookNow: GS.workshopPageState.fromBookNow,
                        clid: GS.visitHistory.join(", "),
                        promoCode: LS.promoCode,
                        accessToken:
                            GS.searchPageState.primarySearchResults.order
                                .userAccessToken,
                        clientWillWait: GS.searchState.immediate,
                    },
                });
            } else {
                res = await bookServiceWithoutOrder({
                    p: {
                        clientWillWait: GS.searchState.immediate,
                        workshopID: selectedWorkshop.ID,
                        email: GS.reservationConfirmationPageState
                            .emailInputVal,
                        phoneNumber:
                            GS.reservationConfirmationPageState.phoneInputVal
                                .trim()
                                .replace(/ /g, ""),
                        marketingConsent: LS.marketingAgreement.value,
                        arrivalDate:
                            GS.reservationConfirmationPageState.date.fullDate,
                        servicesDefinitionsIDs:
                            GS.searchState.selectedServicesDefinitionsIDs,
                        vehicleBrand: GS.searchState.carData.vehicleBrand,
                        vehicleYear: +GS.searchState.carData.vehicleYear,
                        vehicleModel: GS.searchState.carData.vehicleModel,
                        customerComment:
                            GS.reservationConfirmationPageState.comment,
                        fromBookNow: GS.workshopPageState.fromBookNow,
                        clid: GS.visitHistory.join(", "),
                        promoCode: LS.promoCode,
                        rebookingFromOrderID:
                            GS.reservationConfirmationPageState
                                .rebookingFromOrderID,
                    },
                });
            }

            if (res.error) {
                return showToast.error(res.error.message);
            }

            if (res.data) {
                runInAction(() => {
                    GS.reservationSuccessPageState = {
                        completedOrder:
                            res.data && "bookServiceWithoutOrder" in res.data
                                ? res.data.bookServiceWithoutOrder
                                : res.data?.bookService,
                        selectedWorkshop,
                    };
                });
                if (!GS.reservationSuccessPageState.completedOrder) {
                    return console.error("No completedOrder");
                }
                history.push(
                    genOrderSuccessPathname({
                        country: window._COUNTRY,
                        ID: GS.reservationSuccessPageState.completedOrder.ID,
                        userAccessToken:
                            GS.reservationSuccessPageState.completedOrder
                                .userAccessToken,
                    })
                );
            } else {
                console.error("No data");
            }
        } finally {
            runInAction(() => {
                LS.fakeLoading = false;
            });
        }
    }, [bookService, bookServiceWithoutOrder, selectedWorkshop, GS, LS, order]);

    if (!selectedWorkshop) {
        console.error("selectedWorkshop not found");
        return <Redirect to={countryClientPageMeta.HOME.url} />;
    }

    return (
        <S.ConfirmationContainer>
            {/* <Banner>
                Tavo užsakymui taikoma{" "}
                <span style={{ fontWeight: 500 }}>${window._COUNTRY_META.currencySymbol}20 dovana</span> po sėkmingo
                apsilankymo autoservise.
            </Banner> */}
            <S.ReservConfGlobalStyles />
            <S.ConfirmationContentContainer>
                <S.LeftPanel>
                    {!isMobile && (
                        <ConfirmationTitle
                            title={translatables().RESERVATION_CONFIRMATION}
                            iconClassName="icon-lock"
                        />
                    )}
                    <CS.ConfirmationDetailsBox $marginBottom={32}>
                        {RCS.step === ConfStep.Second && (
                            <div>
                                <BackButton
                                    sx={{ mb: 2 }}
                                    onClick={() => {
                                        runInAction(() => {
                                            RCS.step = ConfStep.First;
                                        });
                                    }}
                                >
                                    {transCommonTranslatable("BACK")}
                                </BackButton>
                            </div>
                        )}
                        <InputsGroup>
                            {RCS.step === ConfStep.First ? (
                                <>
                                    <SearchInputs
                                        datePicker={{
                                            calendar:
                                                selectedWorkshop.workHoursCalendar,
                                            city: selectedWorkshop.city,
                                            blockedTimeslots:
                                                initData.data?.service
                                                    .blockedTimeslots,
                                            earliestAvailableDate:
                                                new Date(
                                                    initData.data?.getEarliestAvailableTime.earliestAvailableTime
                                                ) || new Date(),
                                            fnsRef: datePickerFnsRef,
                                            wantedServices,
                                            employees:
                                                initData.data?.service
                                                    .employees,
                                            showImmediateCheckbox:
                                                GS.searchState.showImmediateCheckbox(
                                                    selectedWorkshop
                                                ),
                                            onDateChange:
                                                GS
                                                    .reservationConfirmationPageState
                                                    .date.onDateChange,
                                            dateObj:
                                                GS
                                                    .reservationConfirmationPageState
                                                    .date.obj,
                                        }}
                                        allowedCarBrands={
                                            initData.data?.service
                                                .servicedBrands
                                        }
                                        allInputsRequired
                                        forceErrors={LS.forceInputErrors}
                                        carsAutocompleteFnsRef={
                                            carsAutocompleteFnsRef
                                        }
                                        visibleInputs={{
                                            servicesPicker: false,
                                            carPicker:
                                                !didUserSelectCarFullyOnInit.current ||
                                                LS.showCarPicker,
                                            datePicker: true,
                                        }}
                                    />
                                    <Input
                                        multiline
                                        style={{
                                            marginBottom: 8,
                                            minHeight: "auto",
                                        }}
                                        fullWidth
                                        type="text"
                                        error={LS.commentFieldError}
                                        helperText={transStr(
                                            "Palikite komentarą apie problemą (kažkas bilda, nesikuria, dega lemputė ir t.t)",
                                            { id: "tX1tsIlZ" }
                                        )}
                                        onChange={(e) => {
                                            runInAction(() => {
                                                RCS.comment = e.target.value;
                                            });
                                        }}
                                        value={RCS.comment}
                                        placeholder={
                                            GS.searchState.selectedServicesCustomIDs.includes(
                                                TranslatableCustomID_PopularServices.UNKNOWN_PROBLEM
                                            )
                                                ? transStr("Komentaras", {
                                                      id: "xpg89fdD",
                                                  })
                                                : transStr(
                                                      "Komentaras (neprivaloma)",
                                                      { id: "iei0TU7C" }
                                                  )
                                        }
                                        forceError={LS.forceInputErrors}
                                    />
                                    <S.CommentDescr>
                                        <S.Advice>
                                            <TransMsg
                                                default={"Patarimas:"}
                                                id="reB3tbYs"
                                            />
                                        </S.Advice>{" "}
                                        <TransMsg
                                            default={
                                                "palik komentarą apie gedimą (kažkas bilda / dega lemputė / nesikuria ir t.t), norimas paslaugas ar dalis (pvz., jei planuojate atsivežti savo dalis). Tai padės autoservisui iš anksto pasiruošti jūsų atvykimui, sutaupys jūsų laiką ir padės išvengti nesusipratimų."
                                            }
                                            id="kS8BhUPy"
                                        />
                                    </S.CommentDescr>
                                </>
                            ) : (
                                <>
                                    <Input
                                        fullWidth
                                        leftIconClass="icon-email"
                                        type="email"
                                        placeholder={transStr("El. paštas *", {
                                            id: "qN5GR5GY",
                                        })}
                                        value={
                                            GS.reservationConfirmationPageState
                                                .emailInputVal
                                        }
                                        onChange={(e) => {
                                            runInAction(() => {
                                                GS.reservationConfirmationPageState.emailInputVal =
                                                    e.target.value;
                                            });
                                        }}
                                        error={LS.emailInput.isErr}
                                        helperText={
                                            LS.emailInput.isErr &&
                                            transStr(
                                                "Įvestas neteisingas el. paštas",
                                                { id: "zV2YAJTt" }
                                            )
                                        }
                                        forceError={LS.forceInputErrors}
                                    />
                                    <Input
                                        fullWidth
                                        type="tel"
                                        leftIconClass={"icon-phone"}
                                        onChange={(e) => {
                                            runInAction(() => {
                                                GS.reservationConfirmationPageState.phoneInputVal =
                                                    e.target.value;
                                            });
                                        }}
                                        value={
                                            GS.reservationConfirmationPageState
                                                .phoneInputVal
                                        }
                                        error={LS.phoneInput.isErr}
                                        helperText={
                                            LS.phoneInput.isErr &&
                                            transStr(
                                                "Įvestas neteisingas telefono numeris",
                                                { id: "VxdhhGRT" }
                                            )
                                        }
                                        placeholder={transStr(
                                            "Tel. numeris *",
                                            { id: "Hmdm1rr5" }
                                        )}
                                        forceError={LS.forceInputErrors}
                                    />
                                    <Text variant="subtitle1" fontSize={13}>
                                        <TransMsg
                                            default={
                                                "Šiais kontaktais siųsime informaciją apie rezervacija ir"
                                            }
                                            id="reOajqg3"
                                        />{" "}
                                        <Box
                                            sx={{
                                                fontWeight: 500,
                                                display: "inline",
                                            }}
                                        >
                                            <TransMsg
                                                default={
                                                    "atliktų darbų garantijos patvirtinimą"
                                                }
                                                id="G7q3KRqz"
                                            />
                                        </Box>
                                    </Text>
                                    {LS.promoInputVisible ? (
                                        <Box displayFlex alignItems="center">
                                            <Input
                                                sx={{
                                                    mt: 2,
                                                    maxWidth: 300,
                                                    width: 1,
                                                }}
                                                leftIconClass={"icon-price-tag"}
                                                onChange={(e) => {
                                                    runInAction(() => {
                                                        LS.promoCode =
                                                            e.target.value;
                                                    });
                                                }}
                                                value={LS.promoCode}
                                                placeholder={transStr(
                                                    "Promo kodas (neprivaloma)",
                                                    { id: "9U9mc5JY" }
                                                )}
                                            />
                                            <Button
                                                color="greyish"
                                                sx={{ ml: 2 }}
                                                onClick={() => {
                                                    if (!LS.promoCode) {
                                                        return showToast.warn(
                                                            transStr(
                                                                "Įveskite promo kodą",
                                                                {
                                                                    id: "7SgtEQpg",
                                                                }
                                                            )
                                                        );
                                                    }
                                                    const code =
                                                        LS.promoCode.toLowerCase();
                                                    if (
                                                        code.includes(
                                                            "kurohudas"
                                                        ) ||
                                                        (code.includes("lt") &&
                                                            code.length ===
                                                                10) ||
                                                        code.includes(
                                                            transStr(
                                                                "nuolaida",
                                                                {
                                                                    id: "9heI3GdN",
                                                                }
                                                            )
                                                        )
                                                    ) {
                                                        runInAction(() => {
                                                            GS.reservationConfirmationPageState.promoActivated =
                                                                true;
                                                        });
                                                        showToast.success(
                                                            transStr(
                                                                "Promo kodas aktyvuotas. 10% Nuolaida pritaikyta.",
                                                                {
                                                                    id: "bJ5j3jdI",
                                                                }
                                                            )
                                                        );
                                                    } else {
                                                        runInAction(() => {
                                                            GS.reservationConfirmationPageState.promoActivated =
                                                                false;
                                                        });
                                                        showToast.error(
                                                            transStr(
                                                                "Šis promo kodas neegzistuoja arba jau yra panaudotas",
                                                                {
                                                                    id: "iidunXCl",
                                                                }
                                                            )
                                                        );
                                                    }
                                                }}
                                            >
                                                <TransMsg
                                                    default={"Aktyvuoti"}
                                                    id="IdS4SYW6"
                                                />
                                            </Button>
                                        </Box>
                                    ) : (
                                        <Text
                                            variant="subtitle1"
                                            sx={{
                                                textDecoration: "underline",
                                                mt: 2,
                                                cursor: "pointer",
                                            }}
                                            onClick={() =>
                                                runInAction(() => {
                                                    LS.promoInputVisible = true;
                                                })
                                            }
                                        >
                                            Promo kodas
                                        </Text>
                                    )}
                                </>
                            )}
                        </InputsGroup>
                    </CS.ConfirmationDetailsBox>
                    <Box>
                        {RCS.step === ConfStep.First ? (
                            <Button
                                fullWidth
                                onClick={() => {
                                    runInAction(() => {
                                        LS.forceInputErrors = true;
                                        if (LS.commentFieldError) return;

                                        if (
                                            GS.searchState.carDataArr.length !==
                                            STAGES_ORDER.length
                                        ) {
                                            return;
                                        }
                                        if (
                                            !GS.reservationConfirmationPageState
                                                .date.obj.HH_MM ||
                                            !GS.reservationConfirmationPageState
                                                .date.obj.YYYY_MM_DD
                                        ) {
                                            return;
                                        }

                                        LS.forceInputErrors = false;
                                        RCS.step = ConfStep.Second;
                                    });
                                }}
                            >
                                {transCommonTranslatable("CONTINUE")}
                            </Button>
                        ) : (
                            <>
                                <FormControlLabel
                                    sx={{
                                        width: 1,
                                        margin: "0 auto",
                                        mb: 1,
                                        justifyContent: "center",
                                    }}
                                    label={
                                        <Text fontSize={14}>
                                            <TransMsg
                                                default={
                                                    "Sutinku gauti rinkodaros pranešimus, kaip aprašyta {PrivacyLink <Privatumo Politikoje>}"
                                                }
                                                data={{
                                                    PrivacyLink: (body) => (
                                                        <S.PrivacyLink
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                            }}
                                                            to={"#"}
                                                            target={
                                                                isMobile
                                                                    ? "_self"
                                                                    : "_blank"
                                                            }
                                                            rel="noreferrer"
                                                        >
                                                            {body}
                                                        </S.PrivacyLink>
                                                    ),
                                                }}
                                                id="E4QgxjZQ"
                                            />
                                        </Text>
                                    }
                                    control={
                                        <Checkbox
                                            checked={
                                                LS.marketingAgreement.value
                                            }
                                            onChange={(e) =>
                                                runInAction(() => {
                                                    LS.marketingAgreement.value =
                                                        e.target.checked;
                                                })
                                            }
                                        />
                                    }
                                />
                                <Button
                                    fullWidth
                                    onClick={confirmBtnClick}
                                    isLoading={loading}
                                    leftIconClass={"icon-checkmark-circle"}
                                >
                                    <TransMsg
                                        default={"Patvirtinti rezervaciją"}
                                        id="pd3gzCoX"
                                    />
                                </Button>
                                <S.TosAgreementText>
                                    <TransMsg
                                        default={
                                            "Tęsiant sutinki su užsakymo sąlygomis"
                                        }
                                        id="ogEaFGWn"
                                    />
                                </S.TosAgreementText>
                            </>
                        )}
                    </Box>
                </S.LeftPanel>
                <ConfirmationSidePanel
                    confirmationTitle={
                        isMobile ? (
                            <ConfirmationTitle
                                title={translatables().RESERVATION_CONFIRMATION}
                                iconClassName="icon-lock"
                            />
                        ) : undefined
                    }
                    dateObj={{
                        YYYY_MM_DD:
                            GS.reservationConfirmationPageState.date.obj
                                .YYYY_MM_DD,
                        HH_MM: GS.reservationConfirmationPageState.date.obj
                            .HH_MM,
                    }}
                    onDateClick={() => {
                        runInAction(() => {
                            RCS.step = ConfStep.First;
                            setTimeout(() => {
                                datePickerFnsRef.current?.open();
                            });
                        });
                    }}
                    selectedWorkshop={selectedWorkshop}
                    carData={GS.searchState.carData}
                    onCarClick={() => {
                        runInAction(() => {
                            RCS.step = ConfStep.First;
                            LS.showCarPicker = true;
                            setTimeout(() => {
                                carsAutocompleteFnsRef.current?.open();
                            }, 0);
                        });
                    }}
                />
            </S.ConfirmationContentContainer>
        </S.ConfirmationContainer>
    );
});

export default ReservationConfirmation;

import { transStr } from "@FEShared/i18n";
import capFirst from "@Shared/util/capFirst";

/** Returns May 2d. 09:15 or Today/Tommorow 09:15 */
export default function dateToMonthHourPrettyText(date: Date): string {
    const capitalizedMonth = capFirst(
        date.toLocaleString(window._COUNTRY_META.locale, { month: "long" })
    );
    const day = date.getDate();

    const HH = date.getHours() as number;
    const MM = date.getMinutes() as number;

    let prettyText: string | undefined;
    const dateToCheckAgainst = new Date(date);
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    // Resetting the hours, minutes, seconds, and milliseconds for comparison
    dateToCheckAgainst.setHours(0, 0, 0, 0);
    tomorrow.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    if (dateToCheckAgainst.getTime() === today.getTime()) {
        prettyText = `${transStr("Šiandien", { id: "Hx9BCv94" })},`;
    } else if (dateToCheckAgainst.getTime() === tomorrow.getTime()) {
        prettyText = `${transStr("Rytoj", { id: "M6K2PDSZ" })},`;
    }

    // Adding leading zero to hours and minutes if less than 10
    const paddedHH = HH < 10 ? "0" + HH : HH;
    const paddedMM = MM < 10 ? "0" + MM : MM;

    return `${
        prettyText ? prettyText : `${capitalizedMonth} ${day} d.`
    } ${paddedHH}:${paddedMM}`;
}

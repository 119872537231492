import { CALENDAR_FULL_DAY_SEARCH_TIME } from "@Shared/consts/commonConsts";
import { DateObj } from "@Shared/types/types";
import isValidDate from "./isValidDate";
import capFirst from "./capFirst";
import { DateTime } from "luxon";

type SupportedLocales = "lt-LT" | "en-CA";

export const DEFAULT_LOCALE = "en-CA";

function getTimeZoneOffsetString(date: Date) {
    const offset = date.getTimezoneOffset();
    const hours = Math.floor(Math.abs(offset) / 60);
    const minutes = Math.abs(offset) % 60;

    return `GMT${offset <= 0 ? "+" : "-"}${String(hours).padStart(
        2,
        "0"
    )}${String(minutes).padStart(2, "0")}`;
}

/** Returns YYYY-MM-DD */
export default function dateFormat(date: Date): string {
    const parsedDate = typeof date === "string" ? new Date(date) : date;
    if (!parsedDate || !isValidDate(parsedDate)) {
        console.error(`Received invalid date: ${date}`);
        return "Invalid date";
    }

    return parsedDate.toLocaleDateString("lt-LT");
}

/* Returns YYYY-MM-DD HH:MM */
export function dateFormatFull(date: Date | string): string {
    const parsedDate = typeof date === "string" ? new Date(date) : date;
    if (!parsedDate || !isValidDate(parsedDate)) {
        console.error(`Received invalid date: ${date}`);
        return "";
    }

    const YYYY_MM_DD = parsedDate.toLocaleDateString("lt-LT");
    const splitTimeString = parsedDate.toLocaleTimeString("lt-LT").split(":");
    const HH_MM = `${splitTimeString[0]}:${splitTimeString[1]}`;

    return `${YYYY_MM_DD} ${HH_MM}`;
}

export function dateFormatFullReadableBuildable(date: Date | string): string {
    const parsedDate = typeof date === "string" ? new Date(date) : date;
    if (!parsedDate || !isValidDate(parsedDate)) {
        console.error(`Received invalid date: ${date}`);
        return "";
    }
    const YYYY_MM_DD = parsedDate.toLocaleDateString("lt-LT");
    const splitTimeString = parsedDate.toLocaleTimeString("lt-LT").split(":");
    const HH_MM = `${splitTimeString[0]}:${splitTimeString[1]}`;

    return `${YYYY_MM_DD} ${HH_MM} ${getTimeZoneOffsetString(parsedDate)}`;
}

export function dateToObj(date: Date): DateObj {
    const parsedDate = typeof date === "string" ? new Date(date) : date;
    if (!parsedDate || !isValidDate(parsedDate)) {
        console.error(`Received invalid date: ${date}`);
        return {
            YYYY_MM_DD: undefined,
            HH_MM: undefined,
        };
    }
    const YYYY_MM_DD = parsedDate.toLocaleDateString("lt-LT");
    const splitTimeString = parsedDate.toLocaleTimeString("lt-LT").split(":");
    const HH_MM = `${splitTimeString[0]}:${splitTimeString[1]}`;

    return {
        YYYY_MM_DD,
        HH_MM,
    };
}

export function dateObjToString(p: Partial<DateObj>): string {
    return p.YYYY_MM_DD || p.HH_MM
        ? `${p.YYYY_MM_DD || ""} ${p.HH_MM || ""}`
        : "";
}

/* <TBD> should replace all timeZone usage with timeZone from the workshop city */
export function dateObjToDate(p: DateObj, timeZone: string): Date {
    return DateTime.fromISO(`${p.YYYY_MM_DD}T${p.HH_MM}`, {
        zone: timeZone,
    }).toJSDate();
}

export function dateToMonthDayHour(
    date: Date,
    opts?: {
        locale?: SupportedLocales;
        handleFullDayHour?: boolean;
        // country?: string;
    }
): string {
    const formattedDate = capFirst(
        date.toLocaleDateString(opts?.locale || DEFAULT_LOCALE, {
            year: undefined,
            month: "long",
            day: "numeric",
        })
    );

    const HH = date.getHours() as number;
    const MM = date.getMinutes() as number;

    // Adding leading zero to hours and minutes if less than 10
    const paddedHH = HH < 10 ? "0" + HH : HH;
    const paddedMM = MM < 10 ? "0" + MM : MM;

    return [
        formattedDate,
        opts?.handleFullDayHour &&
        HH === CALENDAR_FULL_DAY_SEARCH_TIME.hour &&
        MM === CALENDAR_FULL_DAY_SEARCH_TIME.minute
            ? undefined
            : `${paddedHH}:${paddedMM}`,
    ]
        .filter(Boolean)
        .join(", ");
}

import GoogleMapMinimal from "@FEClient/views/commonComps/GoogleMapMinimal/GoogleMapMinimal";
import styled from "styled-components";

export const MapAddressWrapper = styled("div")`
    padding: 16px;
    position: absolute;
    top: 8px;
    left: 8px;
    background: white;
    border-radius: ${(p) => p.theme.shape.borderRadius}px;
    z-index: 1;
    box-shadow: ${(p) => p.theme.shadows[3]};

    ${(p) => p.theme.breakpoints.down("md")} {
        position: static;
        padding: 8px;
        width: 100%;
        box-shadow: unset;
        text-align: center;
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;
    }
`;

export const Map = styled(GoogleMapMinimal)`
    border: 1px solid #e0e0e0;
    height: 100%;

    ${(p) => p.theme.breakpoints.down("md")} {
        height: 200px;
        width: auto;
        position: relative;
        margin-right: unset;
    }
`;

import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu/Menu";
import * as S from "./ButtonsMenu.styled";
import { observer, useLocalObservable } from "mobx-react-lite";
import { runInAction } from "mobx";
import Icon from "../Icon/Icon";
import { CircularProgress, PaperProps, SxProps } from "@mui/material";
import Button, { ButtonP } from "../Button/Button";

interface Option<T> {
    leftIconClass?: string;
    title: string;
    value?: T;
    isLoading?: boolean;
    disabled?: boolean;
    onClick: () => void;
}

interface ButtonsMenuP<T> {
    menuButtonText?: string;
    menuIconClass?: string;
    options: Option<T>[];
    value?: T;
    sx?: SxProps;
    btnProps?: ButtonP;
    paperProps?: PaperProps;
}

// taken from https://mui.com/material-ui/react-button-group/#split-button
const ButtonsMenu = observer(<T,>(p: ButtonsMenuP<T>) => {
    const LS = useLocalObservable(() => ({
        anchorEl: null as null | HTMLElement,
        activeOption: p.value
            ? p.options.find((o) => o.value === p.value)
            : null,
    }));

    const open = Boolean(LS.anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        runInAction(() => {
            LS.anchorEl = event.currentTarget;
        });
    };

    const handleClose = () => {
        runInAction(() => {
            LS.anchorEl = null;
        });
    };

    const onButtonClick = (option: Option<T>) => {
        LS.activeOption = option;
        option.onClick();
        handleClose();
    };

    return (
        <React.Fragment>
            {!p.menuButtonText && p.menuIconClass ? (
                <Button
                    circle
                    aria-controls={open ? "buttons-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    // aria-label={LS.activeOption?.title}
                    aria-haspopup="menu"
                    onClick={handleClick}
                    {...p.btnProps}
                >
                    <Icon className={p.menuIconClass} />
                </Button>
            ) : (
                <S.MenuButton
                    sx={p.sx}
                    leftIconClass={p.menuIconClass}
                    aria-controls={open ? "buttons-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    // aria-label={LS.activeOption?.title}
                    aria-haspopup="menu"
                    onClick={handleClick}
                    {...p.btnProps}
                >
                    {p.menuButtonText /* || LS.activeOption?.title */}
                </S.MenuButton>
            )}
            <Menu
                aria-controls={open ? "buttons-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                open={open}
                anchorEl={LS.anchorEl}
                onClose={handleClose}
                sx={{ mt: 0.5, zIndex: 9999999 }}
                PaperProps={{
                    sx: {
                        color: "white",
                    },
                    ...p.paperProps,
                }}
            >
                {p.options.map((option) => (
                    <MenuItem
                        disabled={option.disabled || option.isLoading}
                        key={option.title}
                        onClick={() => onButtonClick(option)}
                    >
                        {option.isLoading && (
                            <CircularProgress
                                size={25}
                                sx={{ mr: 1, color: "white" }}
                            />
                        )}
                        {option.leftIconClass && (
                            <S.LeftIcon className={option.leftIconClass} />
                        )}
                        {option.title}
                    </MenuItem>
                ))}
            </Menu>
        </React.Fragment>
    );
});

export default ButtonsMenu;

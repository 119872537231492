import { IconButton, List, ListItemButton } from "@mui/material";
import styled, { css } from "styled-components";
import Box from "../Box/Box";
import ExpansivePopper from "../ExpansivePopper/ExpansivePopper";
import { EXPANSIVE_POPPER_HEAD_CLASSNAME } from "../ExpansivePopper/ExpansivePopperHead/ExpansivePopperHead";
import ExpansivePopperWithValue, {
    EXPANSION_INPUT_CLASSNAME,
} from "../ExpansivePopperWithValue/ExpansivePopperWithValue";
import Input from "../Input/Input";

/** This wrapper hax is needed for firefox. Firefox doesn't attach eventhandler when input is disabled. */
export const DatePickerInputWrapper = styled("div")`
    pointer-events: all;
    position: relative;
    z-index: 2;
    cursor: pointer;
`;

export const DatePickerInput = styled(Input)<{
    $active: boolean;
    $hide?: boolean;
}>`
    width: 100%;
    z-index: 2;
    position: relative;
    pointer-events: none;

    ${(p) =>
        p.$hide &&
        css`
            display: none;
        `}

    ${(p) =>
        p.$active &&
        css`
            && .MuiOutlinedInput-notchedOutline {
                border-color: ${p.theme.palette.primary.main}!important;
                border-width: 2px;
            }
        `}

    &:hover .MuiOutlinedInput-notchedOutline {
        border-color: black;
    }

    .MuiInputBase-root {
        cursor: pointer;
    }
    .MuiInputBase-input {
        cursor: pointer;
    }

    .MuiInputBase-input {
        color: black;
        -webkit-text-fill-color: black;
    }
`;

export const DatePickerInputRightIcon = styled(IconButton)`
    padding: 2px;
`;

export const CloseCrossIconButton = styled(IconButton)`
    pointer-events: all;
    z-index: 2;
    position: relative;
    margin-right: -8px;
    font-size: 20px;
`;

const ListTitle = styled("div")`
    margin-bottom: 10px;
`;

export const DateListTitle = styled(ListTitle)`
    // mobile res styles are applied
`;

export const TimeListTitle = styled(ListTitle)`
    text-align: center;
`;

export const PopperChildrenWrapper = styled("div")<{
    $noTimeSelector?: boolean;
}>`
    display: flex;
    height: 375px;
    justify-content: center;
    width: 100%;
    padding-top: 12px;
    overflow: hidden;
    display: relative;

    ${(p) =>
        p.$noTimeSelector &&
        css`
            height: auto;
            padding: 8px;
        `}

    ${(p) => p.theme.breakpoints.down("md")} {
        flex: 1;
        height: unset;
    }
`;

export const SelectWrapper = styled("div")`
    display: flex;
    flex-direction: column;
    max-width: 280px;
`;

export const TitleIcon = styled.i`
    margin-right: 6px;
`;

export const TimeSelectWrapper = styled(SelectWrapper)`
    border-left: 1px solid #e6e6e6;
`;

export const ListContainerStyles = css`
    background: rgba(255, 255, 255, 0.7);
    content: "";
    position: absolute;
    height: 50px;
    width: calc(100% - 15px);
    left: 0;
    pointer-events: none;
    z-index: 1;

    ${(p) => p.theme.breakpoints.down("md")} {
        width: 100%;
    }
`;

export const ListWrapper = styled("div")`
    height: 100%;
    position: relative;
    display: flex;
    overflow: hidden;
    min-width: 60px;
    width: 100%;

    /* &::before {
        ${ListContainerStyles}
        top: 0;
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 100%
        );
    }
    &::after {
        ${ListContainerStyles}
        bottom:0;
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 100%
        );
    } */
`;

export const TimesListContainer = styled(List)`
    overflow-y: scroll;
    width: 100%;
    padding-top: 0;
    width: 67px;
`;

export const ListItem = styled(ListItemButton)<{
    $active: boolean;
    $disabled?: boolean;
}>`
    padding: 12px 8px;
    justify-content: center;

    ${(p) =>
        p.$active &&
        css`
            background: ${p.theme.palette.primary.main}!important;
            color: white;
        `}

    ${(p) =>
        p.$disabled &&
        css`
            opacity: 0.4;
            cursor: not-allowed;
        `}
`;

const MOBILE_DATEPICKER_WIDTH = "290px";
export const DateExpansivePopper = styled(ExpansivePopper)`
    display: flex;

    .MuiPickersCalendarHeader-root {
        margin-top: 0;
    }

    .MuiPickersCalendarHeader-root {
        justify-content: space-between;
        padding-left: 12px;
        padding-right: unset;
    }

    .MuiCalendarOrClockPicker-root > div {
        width: unset;
        margin: unset;
    }
    .MuiCalendarPicker-root,
    .MuiPickerStaticWrapper-content {
        width: unset;
        min-width: unset;
    }
    .MuiPickersFadeTransitionGroup-root {
        position: relative;
    }

    .MuiDayPicker-slideTransition {
        min-height: 230px;
    }

    ${(p) => p.theme.breakpoints.down("sm")} {
        ${DateListTitle} {
            padding-left: 12px;
        }

        ${TimesListContainer} {
            width: 100%;
        }

        /* ${TimeSelectWrapper} {
            width: calc(
                ${(p) => p.theme.sizes.lowestMobileWidth} -
                    ${MOBILE_DATEPICKER_WIDTH}
            );
        } */
    }
`;

export const DatePickerContainer = styled(Box)<{ $fixedOpen?: boolean }>`
    position: relative;
    width: 100%;

    ${(p) =>
        p.$fixedOpen &&
        css`
            .DatePicker {
                position: static !important;
                transform: unset !important;
                margin-top: 8px;
                background: transparent;
                box-shadow: none !important;
                border: none !important;
            }

            .MuiOutlinedInput-notchedOutline {
                border: none !important;
            }

            ${PopperChildrenWrapper} {
                max-height: 400px;
            }

            ${`.${EXPANSION_INPUT_CLASSNAME}`}, ${`.${EXPANSIVE_POPPER_HEAD_CLASSNAME}`} {
                display: none;
            }
        `}
`;

import styled from "styled-components";
import ModalDrawer from "../../ModalDrawer/ModalDrawer";
import { DrawerContent } from "../../ModalDrawer/ModalDrawer.styled";

export const ModalDrawerStyled = styled(ModalDrawer)`
    ${DrawerContent} {
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }
`;

import useStore from "@FEClient/logic/store";
import {
    genOrderSuccessPathname,
    countryClientPageToMeta,
} from "@Shared/util/clientPagesMeta";
import { computed, runInAction } from "mobx";
import React from "react";
import * as RCS from "../ReservationConfirmation/ReservationConfirmation.styled";
import { observer, useLocalObservable } from "mobx-react-lite";
import { useCustomersAcceptInvitationMutation } from "@FEShared/graphql/generated/graphql";
import { Redirect } from "react-router-dom";
import { history } from "@FEClient/logic/store";
import useIsMobile from "../../../../../FEShared/hooks/useIsMobile";
import ConfirmationTitle from "@FEClient/views/commonComps/ConfirmationTitle/ConfirmationTitle";
import useToken from "@FEClient/logic/hooks/useToken/useToken";
import * as S from "./AlternativeReservationConfirmation.styled";
import { dateObjToDate, dateToObj } from "@Shared/util/dateFormat";
import Button from "@FEShared/components/UI/Button/Button";
import gqlResHandler from "@FEShared/utils/gqlResHandler";
import Box from "@FEShared/components/UI/Box/Box";
import DatePicker from "@FEShared/components/UI/DatePicker/DatePicker";
import { DateTime } from "luxon";
import serviceCategoriesToWantedServices from "@Shared/util/serviceCategoriesToWantedServices";
import { TransMsg, transStr } from "@FEShared/i18n";

const nowPlusTwoH = DateTime.now().plus({ hours: 2 }).toJSDate();

const AlternativeReservationConfirmation = observer(() => {
    const GS = useStore();
    const isMobile = useIsMobile();
    const token = useToken();
    const [acceptInvitationRes, acceptInvitation] =
        useCustomersAcceptInvitationMutation();
    const LS = useLocalObservable(() => ({
        forceDateErr: false,
    }));
    const dateSelectedOnInit = React.useRef(
        GS.altFlowState.newArrivalDateObj.HH_MM &&
            GS.altFlowState.newArrivalDateObj.YYYY_MM_DD
    );

    const order = GS.searchPageState.altFlow.order;

    const selectedInvitation = React.useMemo(() => {
        const invitation = order?.invitations.find(
            (i) => i.service.ID === GS.searchPageState.selectedServiceId
        );
        return invitation;
    }, [order?.invitations, GS.searchPageState.selectedServiceId]);

    const selectedWorkshop = selectedInvitation?.service;

    if (
        !selectedWorkshop ||
        !order ||
        !GS.searchPageState.selectedServiceId ||
        !order ||
        !selectedInvitation
    ) {
        console.error("redirect");
        console.error(
            `selectedServiceId: ${GS.searchPageState.selectedServiceId}, alternativeSearchOrder: ${order}`
        );
        return (
            <Redirect to={countryClientPageToMeta(window._COUNTRY).HOME.url} />
        );
    }

    const workshopSuggestedNewTime =
        GS.searchPageState.altFlow.newWorkshopSuggestedTime(
            selectedInvitation.service.ID
        )?.new;

    const confirmBtnClick = React.useCallback(async () => {
        runInAction(() => {
            LS.forceDateErr = true;
        });

        if (!order) {
            console.error(
                `selectedServiceId: ${GS.searchPageState.selectedServiceId}, searchResults: ${GS.searchPageState.primarySearchResults}`
            );
            return;
        }
        if (
            workshopSuggestedNewTime &&
            (!GS.altFlowState.newArrivalDateObj.YYYY_MM_DD ||
                !GS.altFlowState.newArrivalDateObj.HH_MM)
        )
            return;

        const res = await acceptInvitation({
            invitationId: selectedInvitation.ID,
            accessToken: token,
            newArrivalDate: dateObjToDate(
                GS.altFlowState.newArrivalDateObj,
                window._COUNTRY_META
                    .defaultTimezone /* <TBD> change to city timezone later*/
            ),
        });

        gqlResHandler(res, (data) => {
            runInAction(() => {
                GS.reservationSuccessPageState = {
                    completedOrder: data.customersAcceptInvitation,
                    selectedWorkshop: selectedWorkshop,
                };
            });
            if (!GS.reservationSuccessPageState.completedOrder) {
                return console.error("No completedOrder");
            }
            history.push(
                genOrderSuccessPathname({
                    ID: GS.reservationSuccessPageState.completedOrder.ID,
                    userAccessToken:
                        GS.reservationSuccessPageState.completedOrder
                            .userAccessToken,
                    country: window._COUNTRY,
                })
            );
        });
    }, [
        selectedWorkshop,
        GS,
        acceptInvitation,
        token,
        selectedInvitation.ID,
        order,
        LS,
        workshopSuggestedNewTime,
    ]);

    const showDatePicker =
        !dateSelectedOnInit.current && workshopSuggestedNewTime;

    const dateObj = workshopSuggestedNewTime
        ? GS.altFlowState.newArrivalDateObj
        : dateToObj(order.arrivalDate);

    const wantedServices = computed(() =>
        GS.getWorkshopSelectedServices(selectedWorkshop).map((s) =>
            serviceCategoriesToWantedServices(s)
        )
    ).get();

    return (
        <S.ConfirmationContainer>
            <S.ConfirmationContentContainer>
                <ConfirmationTitle
                    title={transStr("Rezervacijos patvirtinimas", {
                        id: "bI9BEvLg",
                    })}
                    iconClassName="icon-lock"
                />
                <S.ConfirmationPanel
                    dateObj={{
                        ...dateObj,
                        newArrivalTime: workshopSuggestedNewTime,
                    }}
                    selectedWorkshop={selectedWorkshop}
                    carData={{
                        vehicleBrand: order.vehicleBrand,
                        vehicleModel: order.vehicleModel,
                        vehicleYear: order.vehicleYear,
                    }}
                />
                {showDatePicker && (
                    <Box
                        mt={"8px"}
                        rounded
                        padding={2}
                        textAlign="center"
                        bgcolor="#ffffff"
                    >
                        <DatePicker
                            wantedServices={wantedServices}
                            fixedOpen
                            earliestAvailableDate={nowPlusTwoH}
                            blockedTimeslots={selectedWorkshop.blockedTimeslots}
                            disablePortal
                            dateObj={GS.altFlowState.newArrivalDateObj}
                            onDateChange={(dateObj) => {
                                runInAction(() => {
                                    GS.altFlowState.newArrivalDateObj = {
                                        ...GS.altFlowState.newArrivalDateObj,
                                        ...dateObj,
                                    };
                                });
                            }}
                            placeholder={transStr("Pasirinkite naują laiką", {
                                id: "2nTEy9F1",
                            })}
                            calendar={selectedWorkshop.workHoursCalendar}
                            lunchCalendar={selectedWorkshop.lunchHoursCalendar}
                            city={selectedWorkshop.city}
                            errorsEnabled
                            forceInputError={LS.forceDateErr}
                            employees={selectedWorkshop.employees}
                            immediateValue={Boolean(order.clientWillWait)}
                        />
                    </Box>
                )}
                <Button
                    fullWidth
                    onClick={confirmBtnClick}
                    disabled={acceptInvitationRes.fetching}
                    isLoading={acceptInvitationRes.fetching}
                    sx={{ mt: 3 }}
                >
                    <TransMsg
                        default={"Patvirtinti rezervaciją"}
                        id="dYBKvLI7"
                    />
                </Button>
                <RCS.TosAgreementText>
                    <TransMsg
                        default={"Tęsiant sutinki su užsakymo sąlygomis"}
                        id="D4dPY3WT"
                    />
                </RCS.TosAgreementText>
            </S.ConfirmationContentContainer>
        </S.ConfirmationContainer>
    );
});

export default AlternativeReservationConfirmation;

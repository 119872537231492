import { Interval, DateTime } from "luxon";
import Holidays, { HolidaysTypes } from "date-holidays";
import { Weekday, Calendar, CalendarDay } from "../types/calendar";
import { WEEKDAYS } from "../consts/commonConsts";
import { EmployeeTimeblocks } from "@FEShared/graphql/generated/graphql";
import lOrderBy from "lodash/orderBy";
import lSumBy from "lodash/sumBy";
import isFullDaySearch from "./isFullDaySearch";
import { CITY_TO_LOCALE_MAP } from "@Shared/consts/CITIES";
import dateTimeWithTimeZone from "@Shared/util/dateTimeWithTimeZone";
import { BlockedTimeslotShared } from "@Shared/types/types";

export enum NotAvailableSubReason {
    "BLOCKED_TIMESLOT_GENERAL" = "BLOCKED_TIMESLOT_GENERAL",
    "BLOCKED_TIMESLOT_EMPLOYEE" = "BLOCKED_TIMESLOT_EMPLOYEE",
    "BLOCKED_TIMESLOT_NO_AVAILABLE_MINS" = "BLOCKED_TIMESLOT_NO_AVAILABLE_MINS",
    "BLOCKED_TIMESLOT_NO_AVAILABLE_EMPLOYEES" = "BLOCKED_TIMESLOT_NO_AVAILABLE_EMPLOYEES",
    "BLOCKED_TIMESLOT_ACCEPTANCE_MARGIN" = "BLOCKED_TIMESLOT_ACCEPTANCE_MARGIN",
}

export enum NotAvailableReason {
    "NOT_BUSINESS_DAY" = "NOT_BUSINESS_DAY",
    "HOLIDAY" = "HOLIDAY",
    "LUNCH" = "LUNCH",
    "OUTSIDE_BIZ_HOURS" = "OUTSIDE_BIZ_HOURS",
    "BLOCKED_TIMESLOT" = "BLOCKED_TIMESLOT",
}

export type IsAvailableRes =
    | {
          isAvailable: false;
          reason: NotAvailableReason;
          subReason?: NotAvailableSubReason;
      }
    | {
          isAvailable: true;
      };

const MINS_MARGIN_FOR_IMMEDIATE = 60;
export const ONE_WORK_DAY_IN_MINS = 9 * 60;

type DateHandlerEmployee = {
    employee: EmployeeTimeblocks;
    bizDateHandler: BusinessDateHandler;
    blockedTimeslots?: BlockedTimeslotShared[];
    /**
     * All means, that employee provides all services that workshop provides.
     * If array has same amount of elements as the `wantedServices` that means that employee provides all the wanted services (but has seperately marked workshop services)
     */
    matchingServices: "ALL" | WantedService[];
    // businessMinsLeftCache: Map<string /* YYYY-MM-DD */, number /* minutes */>;
};

export type WantedService = {
    /* ServiceCategory (AKA ServiceDefinition) ID */
    ID: string;
    durationMins: number;
};

type IsDateInsideHoursOptions = {
    checkLunchBreak?: boolean;
};

// For now, timeblock for specific employees only work for all services, not for specific services. But don't think this will be needed anyway.
// TBD adjust this API to accept Date instead of DateTime
export default class BusinessDateHandler {
    /* City in which time calculations should be done. */
    private readonly city: string;
    // mini cache to retrieve holidays faster instead of calling `ltHolidays.getHolidays(year);` everytime because it seems that this operation is quite slow (when done in bulk)
    private readonly holidaysCache: Record<number, HolidaysTypes.Holiday[]> =
        {};

    private readonly workHoursCalendar: Calendar;
    private readonly lunchHoursCalendar?: Calendar;

    private readonly wantedServices?: WantedService[];
    private get wantedServicesDuration(): number {
        return lSumBy(this.wantedServices, "durationMins");
    }
    private readonly employees?: EmployeeTimeblocks[];
    private readonly employeesThatProvideServices = new Map<
        /* Employee.ID */
        number,
        DateHandlerEmployee
    >();
    private readonly generalBlockEvents = [] as BlockedTimeslotShared[];
    private readonly acceptanceDaysMargin?: number;
    private _canAcceptFromDT?: DateTime;
    private timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    public get canAcceptFromDT() {
        if (!this.acceptanceDaysMargin) return;
        if (this._canAcceptFromDT) return this._canAcceptFromDT;

        const canAcceptFrom = this.deltaBusinessMinutes(
            this.dtWithTz(),
            this.acceptanceDaysMargin * ONE_WORK_DAY_IN_MINS
        );
        this._canAcceptFromDT = canAcceptFrom;

        return this._canAcceptFromDT;
    }

    private get shouldCheckEmployeesAvailability(): boolean {
        if (!this.wantedServices || this.wantedServices.length === 0)
            return false;

        if (!this.employees || this.employees.length === 0) return false;

        return true;
    }

    private dtWithTz(dateTime?: DateTime): DateTime {
        return dateTimeWithTimeZone({
            timeZone: this.timeZone,
            dateOrDateTime: dateTime,
        });
    }

    constructor(p: {
        workHoursCalendar: Calendar;
        lunchHoursCalendar: undefined | Calendar;
        /**
         * If event has no employee attached - that means it's a general workshop timeslot block event.
         * If event has employees attached - that means it's a block event for those specific employees.
         * Therefore make sure to not pass standard calendar events, where workshop didn't attach any employee to the event.
         **/
        blockedTimeslots?: BlockedTimeslotShared[];
        wantedServices?: WantedService[];
        employees?: EmployeeTimeblocks[];
        acceptanceDaysMargin?: number;
        city: string;
        /* Used by backend only, since in FE timezone is applied automatically by user's browsers settings. Not optional, so that concious decision would have to be made to pass or not to pass timezone. */
        timeZone: string | undefined;
    }) {
        if (Object.values(p.workHoursCalendar).length === 0)
            throw new Error(
                `workHoursCalendar is empty is businessDateHandler: ${JSON.stringify(
                    p.workHoursCalendar
                )}`
            );

        this.acceptanceDaysMargin = p.acceptanceDaysMargin;
        this.workHoursCalendar = p.workHoursCalendar;
        this.lunchHoursCalendar = p.lunchHoursCalendar;
        this.wantedServices = p.wantedServices;
        this.employees = p.employees;
        this.city = p.city;

        if (p.timeZone) {
            this.timeZone = p.timeZone;
        }

        if (p.blockedTimeslots) {
            this.generalBlockEvents = p.blockedTimeslots.filter(
                (blockEvent) =>
                    !blockEvent.employeesIDs ||
                    blockEvent.employeesIDs.length === 0
            );
        }

        if (this.shouldCheckEmployeesAvailability) {
            p.employees?.forEach((e) => {
                let matchingServices: WantedService[] | "ALL" = [];

                if (
                    e.servicesDefinitionsIDs &&
                    e.servicesDefinitionsIDs.length === 0
                ) {
                    // either employee provides all the same services as the workshop does (no services set)
                    matchingServices = "ALL";
                } else {
                    // or if employee has services set, than check if atleast one service is provided by the employee
                    p.wantedServices?.forEach((wantedService) => {
                        if (
                            e.servicesDefinitionsIDs &&
                            e.servicesDefinitionsIDs.includes(
                                wantedService.ID
                            ) &&
                            Array.isArray(matchingServices)
                        ) {
                            matchingServices.push(wantedService);
                        }
                    });
                }

                if (matchingServices === "ALL" || matchingServices.length > 0) {
                    const employeeEvents = p.blockedTimeslots?.filter(
                        (blockEvent) => blockEvent.employeesIDs?.includes(e.ID)
                    );

                    this.employeesThatProvideServices.set(e.ID, {
                        employee: e,
                        bizDateHandler: new BusinessDateHandler({
                            timeZone: p.timeZone,
                            city: p.city,
                            workHoursCalendar:
                                e.workHoursCalendar || p.workHoursCalendar,
                            blockedTimeslots: employeeEvents?.map((e) => {
                                // remove employeesIDs from blockedTimeslots, so it would be considered as a general block event from employee BusinessDateHandler PoV for `businessMinsBetweenIncludingGeneralBlocks`
                                return {
                                    ...e,
                                    employeesIDs: undefined,
                                };
                            }),
                            lunchHoursCalendar: undefined, // This is 50/50. I think it makes sense not to use lunch hours when calculating employees availability
                        }),
                        matchingServices,
                        blockedTimeslots: employeeEvents,
                        // businessMinsLeftCache: new Map(),
                    });
                }
            });
        }
    }

    /** Adds a business day, and makes sure that returned dateTime is the earliest OPEN time of that business day. */
    public plusBusinessDay(dateTime: DateTime, days?: number): DateTime {
        let newDateTimeTz = this.dtWithTz(dateTime);
        let sanityCheckCounter = 0;
        let isBusinessDay = false;

        while (!isBusinessDay) {
            if (sanityCheckCounter > 30) {
                throw new Error(`Sanity check failed for businessDay`);
            }
            newDateTimeTz = newDateTimeTz.plus({ day: 1 });
            const calendarDay = this.getDateCalendarDay(newDateTimeTz);
            if (!calendarDay) continue;
            newDateTimeTz = newDateTimeTz.set({
                hour: calendarDay.open.hour,
                minute: calendarDay.open.minute,
                second: 0,
                millisecond: 0,
            });

            if (
                this.isDTInsideBusinessHours(newDateTimeTz, {
                    checkLunchBreak: false,
                }).isAvailable
            ) {
                isBusinessDay = true;
            }

            sanityCheckCounter++;
        }
        if (days && days > 0) {
            return this.plusBusinessDay(newDateTimeTz, days - 1);
        }

        return newDateTimeTz;
    }

    /** Minuses a business day, and makes sure that returned dateTime is the CLOSE time of that business day. */
    public minusBusinessDay(dateTime: DateTime): DateTime {
        let isBusinessDay = false;
        let sanityCheckCounter = 0;
        let newDateTimeTz = this.dtWithTz(dateTime);

        while (!isBusinessDay) {
            if (sanityCheckCounter > 30) {
                throw new Error(`Sanity check failed for businessDay`);
            }
            newDateTimeTz = newDateTimeTz.minus({
                day: 1,
            });
            const calendarDay = this.getDateCalendarDay(newDateTimeTz);
            if (!calendarDay) continue;
            newDateTimeTz = newDateTimeTz.set({
                hour: calendarDay.close.hour,
                minute: calendarDay.close.minute,
                second: 0,
                millisecond: 0,
            });
            isBusinessDay = this.isDTInsideBusinessHours(newDateTimeTz, {
                checkLunchBreak: false,
            }).isAvailable;
            sanityCheckCounter++;
        }

        return newDateTimeTz;
    }

    public getDateCalendarDay(
        dateTime: DateTime
    ): CalendarDay | undefined | null {
        const dtWithDz = this.dtWithTz(dateTime);

        const weekday = WEEKDAYS[dtWithDz.weekday - 1] as Weekday;
        const calendarDay = this.workHoursCalendar[weekday];
        return calendarDay;
    }

    public getDateLunchCalendarDay(
        dateTime: DateTime
    ): CalendarDay | undefined | null {
        const dtWithTz = this.dtWithTz(dateTime);

        if (!this.lunchHoursCalendar) return;

        const weekday = WEEKDAYS[dtWithTz.weekday - 1] as Weekday;
        const calendarDay = this.lunchHoursCalendar[weekday];
        return calendarDay;
    }

    private isPublicHoliday(dateTime: DateTime): boolean {
        const year = dateTime.year;

        let retrievedHolidays: HolidaysTypes.Holiday[];
        if (this.holidaysCache[year]) {
            retrievedHolidays = this.holidaysCache[year];
        } else {
            if (!CITY_TO_LOCALE_MAP[this.city]) {
                console.error(`City ${this.city} is not in CITY_TO_LOCALE_MAP`);
            }
            const cityLocale =
                CITY_TO_LOCALE_MAP[this.city] || CITY_TO_LOCALE_MAP.Vilnius;
            const holidays = new Holidays(
                cityLocale.country,
                cityLocale.countryState as string /* bit of type hack because otherwise doesnt match the proper overload */
            );

            retrievedHolidays = holidays.getHolidays(year);
            this.holidaysCache[year] = retrievedHolidays;
        }

        const foundHoliday = retrievedHolidays.find((holiday) => {
            const yyyymmdd = holiday.date.split(" ")[0];
            return yyyymmdd === dateTime.toISODate();
        });
        return !!foundHoliday;
    }

    public isDateInsideCalendarBusinessHours(
        dateTime: DateTime,
        options?: IsDateInsideHoursOptions
    ): IsAvailableRes {
        const dtWithTz = this.dtWithTz(dateTime);
        const calendarDay = this.getDateCalendarDay(dtWithTz);
        const lunchCalendarDay = this.getDateLunchCalendarDay(dtWithTz);
        const mergedOptions = {
            checkLunchBreak: true,
            ...options,
        };

        if (!calendarDay) {
            return {
                isAvailable: false,
                reason: NotAvailableReason.NOT_BUSINESS_DAY,
            };
        }
        if (mergedOptions.checkLunchBreak && lunchCalendarDay) {
            const lunchStartTime = dtWithTz.set({
                hour: +lunchCalendarDay.open.hour,
                minute: +lunchCalendarDay.open.minute,
                second: 0,
                millisecond: 0,
            });
            const lunchStopTime = dtWithTz.set({
                hour: +lunchCalendarDay.close.hour,
                minute: +lunchCalendarDay.close.minute,
                second: 0,
                millisecond: 0,
            });

            const lunchHoursInterval = Interval.fromDateTimes(
                lunchStartTime,
                lunchStopTime
            );

            const isInsideLunchHours = lunchHoursInterval.contains(dtWithTz);
            if (isInsideLunchHours) {
                return {
                    isAvailable: false,
                    reason: NotAvailableReason.LUNCH,
                };
            }
        }

        const openTime = dtWithTz.set({
            hour: +calendarDay.open.hour,
            minute: +calendarDay.open.minute,
            second: 0,
            millisecond: 0,
        });
        const closeTime = dtWithTz.set({
            hour: +calendarDay.close.hour,
            minute: +calendarDay.close.minute,
            second: 0,
            millisecond: 0,
        });

        const serviceWorkHoursInterval = Interval.fromDateTimes(
            openTime,
            closeTime
        );

        const isInsideWorkingHours =
            serviceWorkHoursInterval.contains(dtWithTz);

        return isInsideWorkingHours
            ? { isAvailable: true }
            : {
                  isAvailable: false,
                  reason: NotAvailableReason.OUTSIDE_BIZ_HOURS,
              };
    }

    public hasAvailableEmployees(p: {
        arrivalDT: DateTime;
        immediateReservation?: boolean;
    }): boolean {
        const arrivalDTWithTz = this.dtWithTz(p.arrivalDT);

        let checkForServicesCount: number = this.wantedServices?.length || 0;
        if (!this.shouldCheckEmployeesAvailability) {
            return true;
        }

        if (this.employeesThatProvideServices.size === 0) {
            // if employees were provided, but there are no employees that can provide this service,
            // but this case only should happen if workshop has marked employee services incorrectly.
            console.error(
                `No employees that can provide this services: ${this.wantedServices?.map(
                    (w) => w.ID
                )}. Employees: ${this.employees?.map((e) => e.ID).join(", ")}`
            );
            return true;
        } else {
            // check for scenarios, when one service is not provided by employees but the other is provided.

            const coveredServicesIDs = new Set<string>();
            let differentServicesCountCheck = false;
            for (const e of this.employeesThatProvideServices.values()) {
                if (e.matchingServices === "ALL") {
                    break; // if atleast one employee provides all services, then we don't need to change matchingServicesCount
                } else {
                    differentServicesCountCheck = true;
                    e.matchingServices.forEach((s) =>
                        coveredServicesIDs.add(s.ID)
                    );
                }
            }
            if (differentServicesCountCheck) {
                checkForServicesCount = coveredServicesIDs.size;
            }
        }

        // as a quick heuristic, check first employees biggest range of services and lowest count of blocked events, since that should be the highest probability of finding a match faster.
        const sortedEmployees = lOrderBy(
            Array.from(this.employeesThatProvideServices.values()),
            [
                (e) =>
                    e.matchingServices === "ALL"
                        ? 999
                        : e.matchingServices.length,
                (e) => e.blockedTimeslots?.length || 0,
            ],
            ["desc", "asc"]
        );

        const coveredServicesIDs = new Set<string>();

        for (const employeeData of sortedEmployees) {
            if (
                !employeeData.bizDateHandler.isDTInsideBusinessHours(
                    arrivalDTWithTz
                ).isAvailable
            ) {
                continue;
            }
            if (
                this.isDateInsideEmployeeBlockedTimeslots(
                    arrivalDTWithTz,
                    employeeData
                )
            ) {
                continue;
            }

            if (
                !p.immediateReservation ||
                (p.immediateReservation &&
                    this.doesEmployeeHaveAvailableBizMins({
                        arrivalDT: arrivalDTWithTz,
                        employee: employeeData,
                    }))
            ) {
                if (
                    employeeData.matchingServices === "ALL" ||
                    employeeData.matchingServices.length ===
                        this.wantedServices?.length
                ) {
                    return true;
                } else {
                    employeeData.matchingServices.forEach((s) =>
                        coveredServicesIDs.add(s.ID)
                    );

                    if (coveredServicesIDs.size === checkForServicesCount) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    private doesEmployeeHaveAvailableBizMins(p: {
        arrivalDT: DateTime;
        employee: DateHandlerEmployee;
    }): boolean {
        let freeBizMins = 0;

        if (isFullDaySearch(p.arrivalDT)) {
            const employeeCalendarDay =
                p.employee.bizDateHandler.getDateCalendarDay(p.arrivalDT);
            if (!employeeCalendarDay) return false;

            const startDT = p.arrivalDT.set({
                hour: employeeCalendarDay.open.hour,
                minute: employeeCalendarDay.open.minute,
                second: 0,
                millisecond: 0,
            });

            const endDT = p.arrivalDT.set({
                hour: employeeCalendarDay.close.hour,
                minute: employeeCalendarDay.close.minute,
                second: 0,
                millisecond: 0,
            });

            freeBizMins =
                p.employee.bizDateHandler.businessMinsBetweenIncludingGeneralBlocks(
                    {
                        startDT,
                        endDT,
                    }
                );

            return freeBizMins >= this.wantedServicesDuration;
        } else {
            const checkingDate = {
                dt: p.arrivalDT,
                YYYY_MM_DD: p.arrivalDT.toFormat("YYYY-MM-DD"),
            };

            const employeeCalendarDay =
                p.employee.bizDateHandler.getDateCalendarDay(checkingDate.dt);

            if (!employeeCalendarDay) return false;

            freeBizMins =
                p.employee.bizDateHandler.businessMinsBetweenIncludingGeneralBlocks(
                    {
                        startDT: checkingDate.dt,
                        endDT: checkingDate.dt.plus({
                            minutes:
                                this.wantedServicesDuration +
                                MINS_MARGIN_FOR_IMMEDIATE,
                        }),
                    }
                );

            return freeBizMins >= this.wantedServicesDuration;
        }
    }

    public isDateInsideEmployeeBlockedTimeslots(
        dateTime: DateTime,
        employee: DateHandlerEmployee
    ): boolean {
        const dtWithTz = this.dtWithTz(dateTime);

        if (
            !employee.blockedTimeslots ||
            employee.blockedTimeslots.length === 0
        ) {
            return false;
        }

        const isInsideBlockedTimeslots = employee.blockedTimeslots.some(
            (blockCalendarEvent) => {
                const blockCalendarEventStart = DateTime.fromJSDate(
                    blockCalendarEvent.startDate
                );
                const blockCalendarEventEnd = DateTime.fromJSDate(
                    blockCalendarEvent.endDate
                ).plus({
                    seconds: 1,
                });
                const blockCalendarEventInterval = Interval.fromDateTimes(
                    blockCalendarEventStart,
                    blockCalendarEventEnd
                );
                return blockCalendarEventInterval.contains(dtWithTz);
            }
        );

        return isInsideBlockedTimeslots;
    }

    public isDateInsideBlockedGeneralTimeslots(dateTime: DateTime): boolean {
        const dtWithTz = this.dtWithTz(dateTime);

        if (!this.generalBlockEvents || this.generalBlockEvents.length === 0)
            return false;

        const isInsideBlockedTimeslots = this.generalBlockEvents.some(
            (blockCalendarEvent) => {
                if (
                    this.wantedServices?.length === 0 &&
                    blockCalendarEvent.blockedServicesIDs &&
                    blockCalendarEvent.blockedServicesIDs.length > 0
                ) {
                    // if checking general availability (not for any specific service), and timeblock calendar event is for specific service, then it's not relevant for general availability, so we skip that.
                    return false;
                }

                if (this.wantedServices) {
                    if (
                        blockCalendarEvent.blockedServicesIDs &&
                        blockCalendarEvent.blockedServicesIDs.length > 0 &&
                        this.wantedServices.length > 0 &&
                        !this.wantedServices.some((wantedService) => {
                            const res =
                                blockCalendarEvent.blockedServicesIDs?.includes(
                                    wantedService.ID
                                );
                            return res;
                        })
                    ) {
                        // if this is a specific service block, and it's not blocking any one of the wanted services, we skip it.
                        return false;
                    }
                }

                const blockCalendarEventStart = DateTime.fromJSDate(
                    blockCalendarEvent.startDate
                );
                const blockCalendarEventEnd = DateTime.fromJSDate(
                    blockCalendarEvent.endDate
                ).plus({
                    seconds: 1,
                });
                const blockCalendarEventInterval = Interval.fromDateTimes(
                    blockCalendarEventStart,
                    blockCalendarEventEnd
                );
                return blockCalendarEventInterval.contains(dtWithTz);
            }
        );

        return isInsideBlockedTimeslots;
    }

    /** Should be only called for fullDaySearch OR immediate registrations only */
    public doesWorkshopHaveAvailableBizMins(p: { arrivalDT: DateTime }) {
        const arrivalDTTz = this.dtWithTz(p.arrivalDT);

        if (isFullDaySearch(arrivalDTTz)) {
            const workshopCalendarDay = this.getDateCalendarDay(arrivalDTTz);

            if (!workshopCalendarDay) return false;

            const startDT = arrivalDTTz.set({
                hour: workshopCalendarDay.open.hour,
                minute: workshopCalendarDay.open.minute,
                second: 0,
                millisecond: 0,
            });
            const endDT = arrivalDTTz.set({
                hour: workshopCalendarDay.close.hour,
                minute: workshopCalendarDay.close.minute,
                second: 0,
                millisecond: 0,
            });

            const freeBizMins = this.businessMinsBetweenIncludingGeneralBlocks({
                startDT,
                endDT,
            });

            return freeBizMins >= this.wantedServicesDuration;
        } else {
            // if it's not full day search, then it's immediate reservation;
            const employeeCalendarDay = this.getDateCalendarDay(arrivalDTTz);

            if (!employeeCalendarDay) return false;

            const freeBizMins = this.businessMinsBetweenIncludingGeneralBlocks({
                startDT: arrivalDTTz,
                endDT: arrivalDTTz.plus({
                    minutes:
                        this.wantedServicesDuration + MINS_MARGIN_FOR_IMMEDIATE,
                }),
            });
            return freeBizMins >= this.wantedServicesDuration;
        }
    }

    public isAvailableForServices(
        dateTime: DateTime,
        options?: {
            checkGeneralBlockedTimeslots?: boolean;
            checkEmployeesAvailability?: boolean;
            immediateReservation?: boolean;
        }
    ): IsAvailableRes {
        const dtWithTz = this.dtWithTz(dateTime);

        const isFullDay = isFullDaySearch(dtWithTz);
        const finalOptions = {
            checkGeneralBlockedTimeslots: true,
            checkEmployeesAvailability: true,
            immediateReservation: false,
            ...options,
        };

        const isDTInsideBizHoursRes = this.isDTInsideBusinessHours(dtWithTz);
        if (!isDTInsideBizHoursRes.isAvailable) {
            return {
                isAvailable: false,
                reason: isDTInsideBizHoursRes.reason,
            };
        }

        if (this.canAcceptFromDT && this.canAcceptFromDT > dtWithTz) {
            return {
                isAvailable: false,
                reason: NotAvailableReason.BLOCKED_TIMESLOT,
                subReason:
                    NotAvailableSubReason.BLOCKED_TIMESLOT_ACCEPTANCE_MARGIN,
            };
        }

        if (
            finalOptions.checkGeneralBlockedTimeslots &&
            !isFullDay && // if it's full day search, then it's not relevant to check blocked timeslots, because nothing won't be ever found.
            this.isDateInsideBlockedGeneralTimeslots(dtWithTz)
        ) {
            return {
                isAvailable: false,
                reason: NotAvailableReason.BLOCKED_TIMESLOT,
                subReason: NotAvailableSubReason.BLOCKED_TIMESLOT_GENERAL,
            };
        }

        if (
            finalOptions.checkGeneralBlockedTimeslots &&
            (finalOptions.immediateReservation || isFullDay) &&
            !this.doesWorkshopHaveAvailableBizMins({
                arrivalDT: dtWithTz,
            })
        ) {
            return {
                isAvailable: false,
                reason: NotAvailableReason.BLOCKED_TIMESLOT,
                subReason:
                    NotAvailableSubReason.BLOCKED_TIMESLOT_NO_AVAILABLE_MINS,
            };
        }

        if (
            finalOptions.checkEmployeesAvailability &&
            !this.hasAvailableEmployees({
                arrivalDT: dtWithTz,
                immediateReservation: finalOptions.immediateReservation,
            })
        ) {
            return {
                isAvailable: false,
                reason: NotAvailableReason.BLOCKED_TIMESLOT,
                subReason:
                    NotAvailableSubReason.BLOCKED_TIMESLOT_NO_AVAILABLE_EMPLOYEES,
            };
        }

        return {
            isAvailable: true,
        };
    }

    /* Will only check if the provided DT is a business day, but not whether it's between business hours. */
    public isDTBusinessDay(dateTime: DateTime): boolean {
        const dtWithTz = this.dtWithTz(dateTime);

        if (!this.getDateCalendarDay(dtWithTz)) {
            return false;
        }
        if (this.isPublicHoliday(dtWithTz)) {
            return false;
        }
        return true;
    }

    /*
     * This will check if the provided date is a business day and also if the time is inside business hours.
     *
     * By default, if the provided time is inside lunch time, it will return false. If you want to exclude lunch time, change via options
     */
    public isDTInsideBusinessHours(
        dateTime: DateTime,
        options?: IsDateInsideHoursOptions
    ): IsAvailableRes {
        const dtWithTz = this.dtWithTz(dateTime);

        if (isFullDaySearch(dtWithTz)) {
            if (!this.getDateCalendarDay(dtWithTz)) {
                return {
                    isAvailable: false,
                    reason: NotAvailableReason.NOT_BUSINESS_DAY,
                };
            }
        } else {
            const isDTInsideBizHoursRes =
                this.isDateInsideCalendarBusinessHours(dtWithTz, options);
            if (!isDTInsideBizHoursRes.isAvailable) {
                return {
                    isAvailable: false,
                    reason: isDTInsideBizHoursRes.reason,
                };
            }
        }

        if (this.isPublicHoliday(dtWithTz)) {
            return {
                isAvailable: false,
                reason: NotAvailableReason.NOT_BUSINESS_DAY,
            };
        }

        return {
            isAvailable: true,
        };
    }

    public earliestBusinessDate(): Date {
        const nowDTTz = this.dtWithTz();
        if (this.isDTInsideBusinessHours(nowDTTz).isAvailable)
            return nowDTTz.toJSDate();

        const todayCalendarDay = this.getDateCalendarDay(nowDTTz);
        if (todayCalendarDay) {
            // check if NOW() isn't before the opening time of today. Because if so, then we need to check if opening time is
            const openTime = nowDTTz.set({
                hour: +todayCalendarDay.open.hour,
                minute: +todayCalendarDay.open.minute,
                second: 0,
                millisecond: 0,
            });

            if (
                nowDTTz < openTime &&
                this.isDTInsideBusinessHours(openTime).isAvailable
            ) {
                return openTime.toJSDate();
            }
        }

        const earliestBusinessDate = this.plusBusinessDay(nowDTTz).toJSDate();

        return earliestBusinessDate;
    }

    private businessMinsBetweenIncludingGeneralBlocks(p: {
        startDT: DateTime;
        endDT: DateTime;
    }): number {
        const { startDT, endDT } = p;

        const relevantBlockEvents = this.generalBlockEvents.filter(
            (blockEvent) => {
                const eventStartDT = DateTime.fromJSDate(blockEvent.startDate);
                const eventEndDT = DateTime.fromJSDate(blockEvent.endDate);

                // either event start date or end date is within the interval;
                return (
                    (eventEndDT >= startDT && eventEndDT <= endDT) ||
                    (eventStartDT >= startDT && eventStartDT <= endDT)
                );
            }
        );
        const relevantBlockEventsOrdered = lOrderBy(
            relevantBlockEvents,
            (e) => e.endDate,
            "asc"
        );

        let currentStartDT = startDT;
        let totalBusinessMinutes = 0;

        while (currentStartDT < endDT) {
            const calendarDay = this.getDateCalendarDay(currentStartDT);

            if (calendarDay && !this.isPublicHoliday(currentStartDT)) {
                const openTime = this.dtWithTz().set({
                    year: currentStartDT.year,
                    month: currentStartDT.month,
                    day: currentStartDT.day,
                    hour: calendarDay.open.hour,
                    minute: calendarDay.open.minute,
                    second: 0,
                    millisecond: 0,
                });
                const closeTime = this.dtWithTz().set({
                    year: currentStartDT.year,
                    month: currentStartDT.month,
                    day: currentStartDT.day,
                    hour: calendarDay.close.hour,
                    minute: calendarDay.close.minute,
                    second: 0,
                    millisecond: 0,
                });

                if (currentStartDT < openTime) {
                    // user is booking before opening hours of current day. Set currentDT to openTime.
                    currentStartDT = currentStartDT.set({
                        hour: calendarDay.open.hour,
                        minute: calendarDay.open.minute,
                        second: 0,
                        millisecond: 0,
                    });
                }

                if (openTime <= currentStartDT && currentStartDT < closeTime) {
                    // If currentStartDT is within the business hours of checking day

                    const thisDayEvents = relevantBlockEventsOrdered.filter(
                        (blockEvent) => {
                            const eventStartDT = DateTime.fromJSDate(
                                blockEvent.startDate
                            );
                            const eventEndDT = DateTime.fromJSDate(
                                blockEvent.endDate
                            );

                            const interval = Interval.fromDateTimes(
                                currentStartDT,
                                // whichever comes first.
                                closeTime < endDT
                                    ? closeTime.plus({ minutes: 1 })
                                    : endDT.plus({ minutes: 1 }) // add 1 min to avoid equality check.
                            );

                            return (
                                interval.contains(eventEndDT) ||
                                interval.contains(eventStartDT)
                            );
                        }
                    );

                    thisDayEvents.forEach((calEvent) => {
                        const eventStartDT = DateTime.fromJSDate(
                            calEvent.startDate
                        );
                        const eventEndDT = DateTime.fromJSDate(
                            calEvent.endDate
                        );

                        if (currentStartDT < eventStartDT) {
                            const remainingWorkMinutes = Interval.fromDateTimes(
                                currentStartDT,
                                eventStartDT
                            ).toDuration("minutes").minutes;
                            totalBusinessMinutes += remainingWorkMinutes;
                            currentStartDT = eventEndDT;
                        } else if (
                            currentStartDT >= eventStartDT &&
                            currentStartDT <= eventEndDT
                        ) {
                            currentStartDT = eventEndDT;
                        }
                    });

                    if (endDT > currentStartDT && endDT <= closeTime) {
                        // If endDT is within the current working day, calculate only the necessary minutes
                        totalBusinessMinutes += Interval.fromDateTimes(
                            currentStartDT,
                            endDT
                        ).toDuration("minutes").minutes;
                        break;
                    } else {
                        // Add remaining minutes in the current working day
                        const remainingWorkMinutes = Interval.fromDateTimes(
                            currentStartDT,
                            closeTime
                        ).toDuration("minutes").minutes;
                        totalBusinessMinutes += remainingWorkMinutes;
                        currentStartDT = closeTime;
                    }
                }
            }

            // If it's a non-working day or after business hours, just move to the next day
            currentStartDT = this.plusBusinessDay(currentStartDT);
        }

        return totalBusinessMinutes;
    }

    public businessMinsBetween(p: {
        startDT: DateTime;
        endDT: DateTime;
    }): number {
        // Ensure startDT is before endDT
        const startDTWithTz = this.dtWithTz(p.startDT);
        const endDTWithTz = this.dtWithTz(p.endDT);

        // console.log("startDTWithTz", startDTWithTz.toISO());
        // console.log("endDTWithTz", endDTWithTz.toISO());

        let currentStartDT = startDTWithTz;
        let totalBusinessMinutes = 0;

        while (currentStartDT < endDTWithTz) {
            const calendarDay = this.getDateCalendarDay(currentStartDT);

            if (calendarDay && !this.isPublicHoliday(currentStartDT)) {
                const openTime = this.dtWithTz().set({
                    year: currentStartDT.year,
                    month: currentStartDT.month,
                    day: currentStartDT.day,
                    hour: calendarDay.open.hour,
                    minute: calendarDay.open.minute,
                    second: 0,
                    millisecond: 0,
                });
                const closeTime = this.dtWithTz().set({
                    year: currentStartDT.year,
                    month: currentStartDT.month,
                    day: currentStartDT.day,
                    hour: calendarDay.close.hour,
                    minute: calendarDay.close.minute,
                    second: 0,
                    millisecond: 0,
                });

                if (currentStartDT < openTime) {
                    // user is booking before opening hours of current day. Set currentDT to openTime.
                    currentStartDT = currentStartDT.set({
                        hour: calendarDay.open.hour,
                        minute: calendarDay.open.minute,
                        second: 0,
                        millisecond: 0,
                    });
                }

                if (openTime <= currentStartDT && currentStartDT < closeTime) {
                    // If endDT is within the current working day, calculate only the necessary minutes
                    if (
                        endDTWithTz > currentStartDT &&
                        endDTWithTz <= closeTime
                    ) {
                        const remainingWorkMinutes = Interval.fromDateTimes(
                            currentStartDT,
                            endDTWithTz
                        ).toDuration("minutes").minutes;
                        totalBusinessMinutes += remainingWorkMinutes;
                        break;
                    } else {
                        // Add remaining minutes in the current working day
                        const remainingWorkMinutes = Interval.fromDateTimes(
                            currentStartDT,
                            closeTime
                        ).toDuration("minutes").minutes;
                        totalBusinessMinutes += remainingWorkMinutes;
                        currentStartDT = closeTime;
                    }
                }
            }

            // If it's a non-working day or after business hours, just move to the next day
            currentStartDT = this.plusBusinessDay(currentStartDT);
        }

        return totalBusinessMinutes;
    }

    /** It will either add or subtract business minutes to dateTime. Adds if positive, subtracts if negative */
    public deltaBusinessMinutes(dateTime: DateTime, minutes: number): DateTime {
        const isAdding = minutes >= 0;
        minutes = Math.abs(minutes);
        let currentDTWithTz = this.dtWithTz(dateTime);

        while (minutes > 0) {
            const workHours = this.getDateCalendarDay(currentDTWithTz);

            if (workHours && !this.isPublicHoliday(currentDTWithTz)) {
                const openTime = this.dtWithTz().set({
                    year: currentDTWithTz.year,
                    month: currentDTWithTz.month,
                    day: currentDTWithTz.day,
                    hour: workHours.open.hour,
                    minute: workHours.open.minute,
                    second: 0,
                    millisecond: 0,
                });
                const closeTime = this.dtWithTz().set({
                    year: currentDTWithTz.year,
                    month: currentDTWithTz.month,
                    day: currentDTWithTz.day,
                    hour: workHours.close.hour,
                    minute: workHours.close.minute,
                    second: 0,
                    millisecond: 0,
                });

                if (isAdding) {
                    if (currentDTWithTz >= closeTime) {
                        currentDTWithTz = currentDTWithTz
                            .plus({ days: 1 })
                            .startOf("day"); // might just make sense to use // plusBusiness/minusBusiness
                    } else if (currentDTWithTz < openTime) {
                        currentDTWithTz = openTime;
                    } else {
                        const remainingTodayInterval = Interval.fromDateTimes(
                            currentDTWithTz > openTime
                                ? currentDTWithTz
                                : openTime,
                            closeTime
                        );
                        const remainingWorkMinutes =
                            remainingTodayInterval.toDuration(
                                "minutes"
                            ).minutes;

                        const minutesToAdd = Math.min(
                            minutes,
                            remainingWorkMinutes
                        );
                        currentDTWithTz = currentDTWithTz.plus({
                            minutes: minutesToAdd,
                        });
                        minutes -= minutesToAdd;
                    }
                } else {
                    if (currentDTWithTz <= openTime) {
                        currentDTWithTz = currentDTWithTz
                            .minus({ days: 1 })
                            .endOf("day"); // might just make sense to use // plusBusiness/minusBusiness
                    } else if (currentDTWithTz > closeTime) {
                        currentDTWithTz = closeTime;
                    } else {
                        const remainingTodayInterval = Interval.fromDateTimes(
                            openTime,
                            currentDTWithTz
                        );
                        const remainingWorkMinutes =
                            remainingTodayInterval.toDuration(
                                "minutes"
                            ).minutes;

                        const minutesToSubtract = Math.min(
                            minutes,
                            remainingWorkMinutes
                        );
                        currentDTWithTz = currentDTWithTz.minus({
                            minutes: minutesToSubtract,
                        });
                        minutes -= minutesToSubtract;
                    }
                }
            } else {
                // If it's a non-working day, just move to the next day (or previous if subtracting)
                currentDTWithTz = isAdding
                    ? this.plusBusinessDay(currentDTWithTz)
                    : this.minusBusinessDay(currentDTWithTz);
            }
        }

        return currentDTWithTz;
    }
}

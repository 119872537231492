import { ButtonProps } from "@mui/material/Button";
import React from "react";
import * as S from "./Button.styled";

export interface ButtonP extends ButtonProps {
    className?: string;
    isLoading?: boolean;
    rightIconClass?: string;
    leftIconClass?: string;
    circle?: boolean;
}
const Button: React.FC<ButtonP> = (p) => {
    const {
        isLoading: _,
        rightIconClass: _1,
        leftIconClass: _2,
        ...buttonProps
    } = p;
    return (
        <S.Button
            variant={p.variant ?? "contained"}
            className={p.className}
            {...buttonProps}
            disabled={buttonProps.disabled || p.isLoading}
            $circle={p.circle}
        >
            {p.leftIconClass && (
                <S.LeftIcon className={`${p.leftIconClass} left-icon`} />
            )}
            {p.children}
            {p.rightIconClass && <S.RightIcon className={p.rightIconClass} />}
            {p.isLoading && <S.CircularProgress size={25} />}
        </S.Button>
    );
};
export default Button;

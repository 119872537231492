import styled from "styled-components";
import { absoluteVerticalCenter } from "FEShared/styled/util.styled";
import { SwipeableDrawer } from "@mui/material";
import BackButton from "../BackButton/BackButton";
import Button from "../Button/Button";

export const ModalContent = styled("div")`
    border-radius: ${(p) => p.theme.shape.borderRadius}px;
    background: white;
    max-width: 450px;
    margin: 0 auto;
    padding: 30px;
    position: absolute;
    left: 0;
    right: 0;
    ${absoluteVerticalCenter}
    outline: 0;
`;

export const Drawer = styled(SwipeableDrawer)`
    z-index: 10000;
    box-shadow: 0px -200px 0px 0px rgb(0 0 0 / 70%);

    .MuiPaper-root {
        height: calc(100% - ${(p) => p.theme.sizes.spaceBetweenDrawerAndTop});
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        padding: 8px 16px;
        padding-bottom: 0;
        overflow: hidden;
    }
`;

export const DrawerContent = styled("div")`
    overflow-y: auto;
    flex: 1;
`;

export const Head = styled("div")`
    margin-bottom: 8px;
    display: flex;
`;

export const HeadBackButton = styled(BackButton)`
    height: 40px;
    width: 40px;
    min-width: unset;
    text-align: center;
    padding: 0;
`;

export const ConfirmBtn = styled(Button)`
    margin-left: auto;
    height: 40px;
`;

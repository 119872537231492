import React, { useTransition } from "react";
import { observer } from "mobx-react-lite";

import OverlayContainer from "../../../pages/Search/SearchMap/GoogleMap/OverlayContainer/OverlayContainer";
import * as S from "./GoogleMap.styled";

interface P {
    zoom: number;
    servicePos: google.maps.LatLngLiteral;
    className?: string;
}

const GoogleMap: React.FC<P> = observer((p) => {
    const mapRef = React.useRef<HTMLDivElement | null>(null);
    const [_isPending, startTransition] = useTransition();
    const [map, setMap] = React.useState<google.maps.Map | null>(null);

    React.useEffect(() => {
        if (mapRef.current && !map) {
            const createdMap = new google.maps.Map(mapRef.current, {
                center: p.servicePos,
                zoom: p.zoom,
                gestureHandling: "greedy",
                disableDefaultUI: true,
                clickableIcons: false,
                mapId: "d8195c8f688a13d",
            });

            startTransition(() => {
                setMap(createdMap);
            });
        }
    }, [p.servicePos, p.zoom, map]);

    return (
        <S.GMapWrapper ref={mapRef} className={p.className}>
            <OverlayContainer map={map} position={p.servicePos} key={1}>
                <S.MapPoint className="icon-pin" />
            </OverlayContainer>
        </S.GMapWrapper>
    );
});

export default GoogleMap;

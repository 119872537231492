import useStore from "@FEClient/logic/store";
import formatVehicleDescriptionFE from "@FEShared/utils/formatVehicleDescriptionFE";
import { observer } from "mobx-react-lite";
import React from "react";
import * as S from "./Search.styled";
import SearchMap from "./SearchMap/SearchMap";
import SearchSidebar from "./SearchSidebar/SearchSidebar";
import { UrlPathnameParser } from "./Search.logic";
import { runInAction } from "mobx";
import { useInitiateSearchMutation } from "@FEShared/graphql/generated/graphql";
import useInitSearchFormInfo from "@FEClient/logic/hooks/useInitSearchFormInto/useInitSearchFormInfo";
import { Helmet } from "react-helmet";
import lRemove from "lodash/remove";
import latinize from "latinize";
import capFirst from "@Shared/util/capFirst";
import { countryClientPageToMeta } from "@Shared/util/clientPagesMeta";
import { getDynamicSearchMeta } from "./Search.util";
import { CITIES_WITH_ALL } from "@Shared/consts/CITIES";
import gqlResHandler from "@FEShared/utils/gqlResHandler";

const clientPageMeta = countryClientPageToMeta(window._COUNTRY);

const Search: React.FC = observer((_p) => {
    const GS = useStore();
    const SPS = GS.searchPageState;
    const [initiateSearchRes, initiateSearch] = useInitiateSearchMutation();
    const primarySearchResults = GS.searchPageState.primarySearchResults;
    useInitSearchFormInfo();

    React.useEffect(() => {
        runInAction(() => {
            // this is not the cleanest and most straightforward solution, but w.e for now.
            SPS.altFlow.order = undefined;
        });
    }, [SPS.altFlow]);

    React.useEffect(() => {
        /**
         * reset workshop page date picker, when user navigates back to search page, to handle the following scenario:
         * 1. user goes to a workshop, where earliest available time is 05-30 (but today is 05-15). Therefore we choose the default opened date to be 05-30
         * 2. user goes back to search page, and visits another workshop, where earliest date is 05-15. Therefore we should reset the date picker to 05-15.
         * - We don't want to simply reset `workshopPageState.date` everytime the page is opened, because we want it to stay the same when user navigates between workshop page & reservation conf page.
         */
        GS.workshopPageState.date.onChange({
            YYYY_MM_DD: undefined,
            HH_MM: undefined,
        });
    }, [GS.workshopPageState]);

    React.useEffect(() => {
        runInAction(() => {
            if (initiateSearchRes.fetching) {
                GS.searchPageState.loaders = ["SEARCH_LOADER", "FAKE_LOADER"];
            } else {
                lRemove(
                    GS.searchPageState.loaders,
                    (v) => v === "SEARCH_LOADER"
                );
                GS.searchPageState.loaders = [...GS.searchPageState.loaders];
            }
        });
    }, [initiateSearchRes, GS.searchPageState]);

    React.useEffect(() => {
        const pathname = location.pathname;
        const parsedParams = UrlPathnameParser.pathnameToParams(pathname);
        runInAction(() => {
            if (parsedParams.city) {
                if (
                    !(CITIES_WITH_ALL[window._COUNTRY] as string[]).includes(
                        parsedParams.city
                    )
                ) {
                    return window.location.replace(
                        UrlPathnameParser.paramsToPathname({
                            city: window._COUNTRY_META.defaultCity,
                        })
                    );
                }
                GS.searchState.city = parsedParams.city;
            }
            if (parsedParams.serviceDefinitionID) {
                GS.searchState.selectedServicesDefinitionsIDs = [
                    parsedParams.serviceDefinitionID,
                ];
            }
            if (parsedParams.carBrand) {
                GS.searchState.carDataArr = [parsedParams.carBrand];
            }
        });
        async function initSearch() {
            const res = await initiateSearch({
                params: GS.searchState.initiateSearchParams,
            });

            gqlResHandler(res, (data) => {
                GS.searchState.setInitateSearchRes(data.initiateSearch);
            });
        }
        initSearch();
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    const carBrand = GS.searchState.carData.vehicleBrand
        ? GS.searchState.carData.vehicleBrand
        : "TOP";

    const firstServiceID = GS.searchState.selectedServicesDefinitionsIDs[0];
    const firstServiceName = GS.searchState.selectedServicesTransNames[0];
    const serviceName = firstServiceName ? firstServiceName : undefined;
    const dynamicPageMeta = getDynamicSearchMeta({
        country: window._COUNTRY,
        carBrandOrTOP: carBrand,
        serviceName,
        city: GS.searchState.city,
    });

    // TBD: Better to make all this JSON+LD URLs building via `buildUrl`. But would need to move it to `Shared` first.
    return (
        <S.SearchContainer id="search-container">
            <Helmet>
                <title>{dynamicPageMeta.title}</title>
                <meta name="description" content={dynamicPageMeta.descr} />
                <link
                    rel="canonical"
                    href={`https://${
                        window._COUNTRY_META.domain
                    }${UrlPathnameParser.paramsToPathname({
                        city: GS.searchState.city,
                        carBrand: GS.searchState.carData.vehicleBrand,
                        serviceDefinitionID: firstServiceID,
                    })}`}
                />
                <script type="application/ld+json">
                    {`
                            {
                                "@context": "http://schema.org",
                                "@type": "BreadcrumbList",
                                "numberOfItems": ${serviceName ? 3 : 2},
                                "itemListElement": [
                                  {
                                    "@type": "ListItem",
                                    "position": 1,
                                    "item": {
                                      "@type": "WebPage",
                                      "@id": "https://${
                                          window._COUNTRY_META.domain
                                      }${clientPageMeta.SEARCH.url}",
                                      "name": "${
                                          dynamicPageMeta.workshopKeyword
                                      }"
                                    }
                                  },
                                  {
                                    "@type": "ListItem",
                                    "position": 2,
                                    "item": {
                                      "@type": "WebPage",
                                      "@id": "https://${
                                          window._COUNTRY_META.domain
                                      }${
                        clientPageMeta.SEARCH.url
                    }/${UrlPathnameParser.urlifyCity(GS.searchState.city)}",
                                      "name": "${capFirst(
                                          latinize(GS.searchState.city)
                                      )}"
                                    }
                                  }
                                  ${
                                      firstServiceName
                                          ? `,{
                                    "@type": "ListItem",
                                    "position": 3,
                                    "item": {
                                      "@type": "WebPage",
                                      "@id": "https://${
                                          window._COUNTRY_META.domain
                                      }/${
                                                clientPageMeta.SEARCH.url
                                            }/${UrlPathnameParser.urlifyCity(
                                                GS.searchState.city
                                            )}/${UrlPathnameParser.urlifyServiceName(
                                                {
                                                    country: window._COUNTRY,
                                                    serviceDefinitionID:
                                                        firstServiceID,
                                                }
                                            )}",
                                      "name": "${capFirst(
                                          latinize(firstServiceName)
                                      )}"
                                    }
                                  }`
                                          : ""
                                  }
                                ]
                              }
                        `}
                </script>
            </Helmet>
            {/* <TopSearchBar /> */}
            <S.SidebarNMapWrapper>
                <SearchSidebar
                    isLoading={
                        !primarySearchResults || GS.searchPageState.isLoading
                    }
                    carDescrTitle={
                        primarySearchResults?.order
                            ? formatVehicleDescriptionFE(
                                  primarySearchResults.order
                              )
                            : ""
                    }
                    workshops={primarySearchResults?.services || []}
                />
                <SearchMap
                    city={GS.searchState.city}
                    mapItems={primarySearchResults?.services || []}
                />
            </S.SidebarNMapWrapper>
        </S.SearchContainer>
    );
});

export default Search;

import React from "react";
import * as S from "./ConfirmationSidePanel.styled";
import { observer, useLocalObservable } from "mobx-react-lite";
import ConfirmationRowItem, {
    ConfirmationRowItemP,
} from "../ConfirmationRowItem/ConfirmationRowItem";
import formatVehicleDescriptionFE from "@FEShared/utils/formatVehicleDescriptionFE";
import capFirst from "@Shared/util/capFirst";
import { ConfirmationWorkshop } from "./ConfirmationSidePanel.types";
import { OrderCarData } from "@Shared/types/types";
import useStore from "@FEClient/logic/store";
import { runInAction } from "mobx";
import WarrantyModal from "@FEClient/views/pages/Warranty/WarrantyModal/WarrantyModal";
import Text from "@FEShared/components/UI/Text/Text";
import Box from "@FEShared/components/UI/Box/Box";
import { TransMsg, transStr, transStringFunctionalFE } from "@FEShared/i18n";
import transCommonTranslatable from "@FEShared/utils/transCommonTranslatable";
import getServicesPriceNDuration from "@FEShared/utils/getServicesPriceNDuration";

const ConfirmationSidePanel: React.FC<{
    createdByWorkshop?: boolean;
    confirmationTitle?: JSX.Element;
    footer?: JSX.Element;
    selectedWorkshop: ConfirmationWorkshop;
    carData: OrderCarData;
    dateObj: {
        YYYY_MM_DD?: string;
        HH_MM?: string;
        newArrivalTime?: boolean;
    };
    className?: string;
    onDateClick?: () => void;
    // both onclick handlers not used atm but let em stay here for now
    onServicesClick?: () => void;
    onCarClick?: () => void;
}> = observer((p) => {
    const GS = useStore();

    const LS = useLocalObservable(() => ({
        warrantyModalOpen: false,
    }));

    const workshopSelectedServices = GS.getWorkshopSelectedServices(
        p.selectedWorkshop
    );

    const orderCategories: ConfirmationRowItemP[] =
        workshopSelectedServices.map((service, i) => {
            const priceNDuration = getServicesPriceNDuration(
                [service],
                p.selectedWorkshop.hourCost
            );

            return {
                id: Number(3 + i),
                label: priceNDuration
                    ? [
                          priceNDuration.servicesPrice.text,
                          priceNDuration.partsPrice.text
                              ? `${transCommonTranslatable("PARTS")}: ${
                                    priceNDuration.partsPrice.text
                                }`
                              : undefined,
                      ]
                          .filter(Boolean)
                          .join(". ")
                    : undefined,
                value: transStringFunctionalFE(service.type.serviceName),
                icon: i === 0 ? "icon-wrench-full" : undefined,
            };
        });

    const rightPanelRowsData = [
        {
            id: 0,
            label: transStr("Autoservisas", { id: "W1Dno92M" }),
            value: p.selectedWorkshop.name,
            icon: "icon-tent",
        },
        {
            id: 1,
            label: transStr("Adresas", { id: "61xk6iXU" }),
            value: p.selectedWorkshop.address,
            icon: "icon-pin",
        },
        p.carData.vehicleBrand && {
            id: 2.1,
            label: transStr("Automobilis", { id: "PXGYQIN0" }),
            value: p.carData.vehicleBrand
                ? formatVehicleDescriptionFE(p.carData)
                : transStr("Pasirinkite automobilį", { id: "ijtcU5Ja" }),
            icon: "icon-car",
            onClick: p.onCarClick,
        },
        ...orderCategories,
    ].filter(Boolean) as {
        id: number;
        label: string;
        value: string;
        icon: string;
        onClick?: () => void;
    }[];

    const priceNDuration = GS.getSelectedServicesPriceNDurationForWorkshop(
        p.selectedWorkshop
    );

    return (
        <S.PanelContainer className={p.className}>
            <WarrantyModal
                isOpen={LS.warrantyModalOpen}
                onClose={() =>
                    runInAction(() => {
                        LS.warrantyModalOpen = false;
                    })
                }
            />
            {p.confirmationTitle}
            <S.Panel>
                <S.OrderDetailsWrapper>
                    <S.DateAndTimeWrapper
                        $warning={p.dateObj.newArrivalTime}
                        onClick={() => {
                            p.onDateClick?.();
                        }}
                    >
                        <Box displayFlex alignVertical="center">
                            {p.dateObj.HH_MM ? (
                                <S.Time>{p.dateObj.HH_MM}</S.Time>
                            ) : (
                                <S.Time>
                                    <span>--</span>
                                    <span>:</span>
                                    <span>--</span>
                                </S.Time>
                            )}
                            <S.SeparatorVertical />
                            <S.DateWrapper>
                                {p.dateObj.YYYY_MM_DD ? (
                                    <>
                                        <S.Weekday>
                                            {p.dateObj.YYYY_MM_DD &&
                                                new Date(
                                                    p.dateObj.YYYY_MM_DD
                                                ).toLocaleDateString(
                                                    window._COUNTRY_META.locale,
                                                    {
                                                        weekday: "long",
                                                    }
                                                )}
                                        </S.Weekday>
                                        <S.MonthAndDayNr>
                                            {p.dateObj.YYYY_MM_DD &&
                                                capFirst(
                                                    new Date(
                                                        p.dateObj.YYYY_MM_DD
                                                    ).toLocaleDateString(
                                                        window._COUNTRY_META
                                                            .locale,
                                                        {
                                                            month: "long",
                                                            day: "numeric",
                                                        }
                                                    )
                                                )}
                                        </S.MonthAndDayNr>
                                    </>
                                ) : (
                                    <S.MonthAndDayNr>
                                        <TransMsg
                                            default={"Pasirinkite datą"}
                                            id="0LpFkNSU"
                                        />
                                    </S.MonthAndDayNr>
                                )}
                            </S.DateWrapper>
                        </Box>
                        {p.dateObj.newArrivalTime && (
                            <Text color="warning.main">
                                {!p.dateObj.HH_MM || !p.dateObj.YYYY_MM_DD
                                    ? transStr("Pasirinkite naują laiką", {
                                          id: "ssKXx6Gj",
                                      })
                                    : transStr("Pasirinktas naujas laikas", {
                                          id: "Qp3SEIjG",
                                      })}
                            </Text>
                        )}
                    </S.DateAndTimeWrapper>
                    {rightPanelRowsData.map((orderDetail) => (
                        <ConfirmationRowItem
                            key={orderDetail.id}
                            id={orderDetail.id}
                            value={orderDetail.value}
                            label={orderDetail.label}
                            iconClassName={orderDetail.icon}
                            onClick={
                                "onClick" in orderDetail
                                    ? orderDetail.onClick
                                    : undefined
                            }
                        />
                    ))}
                    <S.SeparatorHorizontal />
                    <S.PaymentInfoWrapper>
                        <S.PaymentTextPrimary>
                            {[
                                transStr("Apmokėjimas autoservise:", {
                                    id: "9l18c1re",
                                }),
                                priceNDuration?.totalPrice.value &&
                                priceNDuration.totalPrice.value > 0
                                    ? priceNDuration?.totalPrice.text
                                    : undefined,
                            ]
                                .filter(Boolean)
                                .join(" ")}
                        </S.PaymentTextPrimary>
                        {GS.reservationConfirmationPageState.promoActivated && (
                            <S.PaymentTextSecondary>
                                <TransMsg
                                    default={
                                        "{discountPercent} nuolaida aktyvuota"
                                    }
                                    data={{ discountPercent: "10%" }}
                                    id="O5hirLhX"
                                />
                            </S.PaymentTextSecondary>
                        )}
                    </S.PaymentInfoWrapper>
                </S.OrderDetailsWrapper>
            </S.Panel>
            {p.footer}
        </S.PanelContainer>
    );
});

export default ConfirmationSidePanel;

import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { WorkshopDTO } from "../../../Workshop.types";
import React from "react";
import useStore from "@FEClient/logic/store";
import Box from "@FEShared/components/UI/Box/Box";
import Text from "@FEShared/components/UI/Text/Text";
import DatePicker from "@FEShared/components/UI/DatePicker/DatePicker";
import Button from "@FEShared/components/UI/Button/Button";
import Icon from "@FEShared/components/UI/Icon/Icon";
import Tooltip from "@mui/material/Tooltip";
import serviceCategoriesToWantedServices from "@Shared/util/serviceCategoriesToWantedServices";
import { Skeleton } from "@mui/material";
import getServicesPriceNDuration from "@FEShared/utils/getServicesPriceNDuration";
import { TranslatableView } from "@FEShared/i18n";
import { TransMsg, transStr } from "@FEShared/i18n";
import transCommonTranslatable from "@FEShared/utils/transCommonTranslatable";

const ReservationBoxContent: React.FCC<{
    selectedWorkshop: WorkshopDTO;
    showTitle?: boolean;
}> = observer((p) => {
    const GS = useStore();

    // Eh this is a bit not clean/unclear. This could be solved by using `useComputed` from newest mobx-react-lite, but don't want to update now.
    // We use selectedServicesDefinitionsIDs as dependency, because on it depends the result of `getWorkshopSelectedServices`
    const memoizedServices = React.useMemo(() => {
        return GS.getWorkshopSelectedServices(p.selectedWorkshop);
    }, [GS, p.selectedWorkshop, GS.searchState.selectedServicesDefinitionsIDs]); //eslint-disable-line react-hooks/exhaustive-deps

    const priceNDuration = GS.getSelectedServicesPriceNDurationForWorkshop(
        p.selectedWorkshop
    );

    const hasSelectedServices = memoizedServices.length > 0;

    const wantedServices = React.useMemo(
        () => memoizedServices.map((s) => serviceCategoriesToWantedServices(s)),
        [memoizedServices]
    );

    return (
        <>
            <Box rounded mb={3}>
                {p.showTitle && (
                    <Text semiBold>
                        <TransMsg
                            default={"Laisvi laikai registracijai"}
                            id="wq2y5Ckp"
                        />
                    </Text>
                )}
                <DatePicker
                    hideValueInput
                    disablePortal
                    fixedOpen
                    blockedTimeslots={p.selectedWorkshop.blockedTimeslots}
                    calendar={p.selectedWorkshop.workHoursCalendar}
                    lunchCalendar={p.selectedWorkshop.lunchHoursCalendar}
                    city={p.selectedWorkshop.city}
                    earliestAvailableDate={
                        GS.workshopPageState.earliestAvailableTime
                    }
                    dateObj={GS.workshopPageState.date.obj}
                    onDateChange={GS.workshopPageState.date.onChange}
                    placeholder={transStr("Pasirinkite registracijos laiką", {
                        id: "5lQ96s9b",
                    })}
                    wantedServices={wantedServices}
                    employees={p.selectedWorkshop.employees}
                    immediateValChange={GS.searchState.immediateValChange}
                    immediateValue={GS.searchState.immediate}
                    showImmediateCheckbox={GS.searchState.showImmediateCheckbox(
                        p.selectedWorkshop
                    )}
                />
            </Box>
            <Button
                leftIconClass={"icon-checkmark-circle"}
                fullWidth
                disabled={!hasSelectedServices}
                onClick={() => GS.workshopPageState.onConfirm()}
            >
                {hasSelectedServices
                    ? transStr("Tęsti rezervaciją", { id: "hRXmuntu" })
                    : transStr("Pasirinkite paslaugas", { id: "6Mh2gfya" })}
            </Button>
            <Text
                color="primary"
                textAlign="center"
                mt={1}
                fontSize={14}
                onClick={(e) => e.stopPropagation()}
            >
                <Icon className="icon-bolt" mr={1} />
                <TransMsg
                    default={"Garantuota momentinė rezervacija"}
                    id="5wYp0ukx"
                />
                <Tooltip
                    title={transStr(
                        "Autoservisas yra atnaujinęs savo užimtumą ir yra pasiruošęs jus priimti visais aukščiau matomais laisvais laikais.",
                        { id: "ITLPFLl7" }
                    )}
                >
                    <Box display="inline-block">
                        <Icon
                            sx={{ cursor: "pointer" }}
                            ml={0.5}
                            display="inline-block"
                            className="icon-info-circle"
                            color="#7B7F93"
                        />
                    </Box>
                </Tooltip>
            </Text>
            {
                <Box mt={2} borderTop="1px solid #D6D9E8" pt={2}>
                    {memoizedServices.map((service) => {
                        const priceNDuration = getServicesPriceNDuration(
                            [service],
                            p.selectedWorkshop.hourCost
                        );

                        return (
                            <Box key={service.type.serviceName.id} mb={0.5}>
                                <Box displayFlex justifyContent="space-between">
                                    <Text
                                        variant="subtitle1"
                                        alignItems="center"
                                        display="flex"
                                        mr={2}
                                    >
                                        <Icon
                                            mr={0.5}
                                            pointer
                                            className="icon-cross"
                                            onClick={() => {
                                                runInAction(() => {
                                                    GS.searchState.selectedServicesDefinitionsIDs =
                                                        GS.searchState.selectedServicesDefinitionsIDs.filter(
                                                            (ID) =>
                                                                ID !==
                                                                service.type.ID
                                                        );
                                                });
                                            }}
                                        />
                                        <TranslatableView
                                            translatable={
                                                service.type.serviceName
                                            }
                                        />
                                    </Text>
                                    {GS.workshopPageState.isLoading ? (
                                        <Skeleton
                                            width="37px"
                                            height="20px"
                                            variant="rectangular"
                                            sx={{ borderRadius: "15px" }}
                                        />
                                    ) : (
                                        <Text
                                            variant="subtitle1"
                                            whiteSpace="nowrap"
                                        >
                                            {priceNDuration.servicesPrice
                                                .text || "-"}
                                        </Text>
                                    )}
                                </Box>
                                {priceNDuration.partsPrice.text && (
                                    <Box
                                        pl={1.5}
                                        displayFlex
                                        justifyContent="space-between"
                                    >
                                        <Text
                                            variant="subtitle1"
                                            alignItems="center"
                                            display="flex"
                                        >
                                            • {transCommonTranslatable("PARTS")}
                                        </Text>
                                        {GS.workshopPageState.isLoading ? (
                                            <Skeleton
                                                width="37px"
                                                height="20px"
                                                variant="rectangular"
                                                sx={{ borderRadius: "15px" }}
                                            />
                                        ) : (
                                            <Text variant="subtitle1">
                                                {priceNDuration.partsPrice
                                                    .text || "-"}
                                            </Text>
                                        )}
                                    </Box>
                                )}
                            </Box>
                        );
                    })}
                    <Box mt={1} justifyContent="space-between" displayFlex>
                        <Text semiBold>
                            <TransMsg
                                default={"Viso"}
                                id="7EXbSKQS"
                                description=" Viso {sum}"
                            />
                        </Text>
                        {GS.workshopPageState.isLoading ? (
                            <Skeleton
                                width="37px"
                                height="20px"
                                variant="rectangular"
                                sx={{ borderRadius: "15px" }}
                            />
                        ) : (
                            <Text semiBold>
                                {priceNDuration?.totalPrice.text ||
                                    `${window._COUNTRY_META.currencySymbol}0.00`}
                            </Text>
                        )}
                    </Box>
                    <Text
                        color="#29A370"
                        center
                        mt={1}
                        sx={{ cursor: "pointer" }}
                        fontSize={12}
                        onClick={() => {
                            GS.workshopPageState.warrantyModalOpen = true;
                        }}
                    >
                        <Icon className="icon-lock" mr={1} />
                        <Text
                            span
                            variant="inherit"
                            sx={{ textDecoration: "underline" }}
                        >
                            <TransMsg
                                default={"100% Darbų Garantija"}
                                id="xuzZpNzo"
                            />
                        </Text>
                        <Text fontSize={10}>
                            <TransMsg
                                default={
                                    "Paslaugos apsaugotos techninio advokato"
                                }
                                id="bnk1zgFU"
                            />
                        </Text>
                    </Text>
                </Box>
            }
        </>
    );
});
ReservationBoxContent.defaultProps = {
    showTitle: true,
};

export default ReservationBoxContent;
